import {
  Datagrid,
  EditButton,
  List,
  ReferenceField,
  SelectField,
  ShowButton,
  TextField
} from "react-admin";

import { PolicyRole, PolicyStatus, PolicyType } from "./choices";

const PolicyList = () => (
  <List pagination={false}>
    <Datagrid
      rowClick={null}
      bulkActionButtons={false}
    >
      <SelectField source="type" choices={PolicyType} />
      <TextField source="version" sortable={false} />
      <SelectField source="role" choices={PolicyRole} sortable={false} />
      <SelectField source="status" choices={PolicyStatus} sortable={false} />
      <ReferenceField
        label="Applies to institution"
        source="institutionId"
        reference="institutions"
        emptyText="-"
        sortable={false}
      />
      <EditButton label="Edit" />
      <ShowButton label="Show" />
    </Datagrid>
  </List>
);

export default PolicyList;
