import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { FC, useContext, useState } from "react";
import {
  Button as ReactAdminButton,
  useDataProvider,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useMutation } from "react-query";
import { AppRoleContext } from "src/context/AppRoleContext/AppRoleContext";

import { CustomDataProvider } from "../auth";
import { Institution } from "./types";

export const DisableInstitutionButton: FC = () => {
  const { role } = useContext(AppRoleContext);
  const { id, disabled } = useRecordContext<Institution>();
  const dataProvider = useDataProvider<CustomDataProvider>();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");

  const { mutateAsync } = useMutation((reason?: string) =>
    dataProvider.enableInstitution(id, {
      enabled: disabled,
      reason: reason,
    })
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    await mutateAsync(reason === "" ? undefined : reason);
    setOpen(false);
    setReason("");
    refresh();
  };

  return (
    <>
      <ReactAdminButton
        label={disabled ? "enable" : "disable"}
        onClick={(e) => {
          e.stopPropagation();
          handleClick();
        }}
        disabled={role !== "SUPERUSER"}
      />
      <Dialog
        open={open}
        onClose={handleDialogClose}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>
          {disabled ? "Enable institution" : "Disable institution"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            margin="dense"
            id="disable-reason"
            label="Reason"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              void handleConfirm();
            }}
            disabled={!reason && !disabled}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
