import { Box } from "@mui/material";
import { Create, TabbedForm } from "react-admin";

import { useMutationErrorNotify } from "../common/handleMutationError";
import ActivityEvaluation from "./subforms/activityEvaluation";
import { ActivityItems } from "./subforms/activityItems";
import { ActivityOverview } from "./subforms/activityOverview";
import { ActivityProfessionalInformation } from "./subforms/activityProfessionalInformations";
import ActivityScoring from "./subforms/activityScoring";
import { transformDataBeforeRequest } from "./transformDataBeforeRequest";

const ActivityCreate = () => {
  const notify = useMutationErrorNotify();

  return (
    <Create
      title="Activity"
      transform={transformDataBeforeRequest}
      mutationOptions={{
        onError: notify
      }}
    >
      <TabbedForm>
        <TabbedForm.Tab label="Overview">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <ActivityOverview />
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Professional Information">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <ActivityProfessionalInformation />
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Items">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <ActivityItems />
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Scoring">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <ActivityScoring />
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Evaluation">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <ActivityEvaluation />
          </Box>
        </TabbedForm.Tab>
      </TabbedForm>
    </Create>
  );
};

export default ActivityCreate;
