import { FC, useMemo } from "react";
import { BooleanInput } from "react-admin";
import { ActivityScore } from "src/activities/types";

const getIndexFromSource = (source: string): number | undefined => {
  const index = source.split(".").find((key) => !isNaN(Number(key)));

  return typeof index === "string" ? parseInt(index) : undefined;
};

interface ScoreTypeConfigProps {
  scores: Array<ActivityScore>;
  getSource: (name: string) => string;
}

export const ScoreTypeConfig: FC<ScoreTypeConfigProps> = ({
  scores,
  getSource
}) => {
  const index = useMemo(() => getIndexFromSource(getSource("")), [getSource]);

  const isMainScoreDisabled = useMemo(
    () =>
      (!scores[index]?.isMainScore &&
        scores.some((score) => score?.isMainScore)) ||
      scores[index]?.isIntermediate,
    [scores, index]
  );

  const isIntermediateDisabled = useMemo(
    () => scores[index]?.isMainScore,
    [scores, index]
  );
  return (
    <>
      <BooleanInput
        source={getSource("isMainScore")}
        helperText="If selected it will be considered the questionnaire's score"
        disabled={isMainScoreDisabled}
      />
      <BooleanInput
        source={getSource("isIntermediate")}
        helperText="Intermediate scores won't be displayed to the practitioner"
        disabled={isIntermediateDisabled}
      />
    </>
  );
};
