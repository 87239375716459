import { FC } from "react";
import { ArrayInput, AutocompleteInput, ReferenceInput, required,SelectInput,SimpleFormIterator } from "react-admin";
import { commonItemsStyling } from "src/activities/commonStyling";
import { FormItemButtons } from "src/components/FormItemButtons";
import { JournalPage } from "src/journalsPages/types";

import { JournalTimePeriod } from "../choices";

export const JournalPages: FC = () => {
  return (
    <>
      <ArrayInput
        source="pages"
        required
        validate={(values: JournalPage[]) =>
          values && values.length
            ? undefined
            : "At least one page is required."
        }
      >
        <SimpleFormIterator
          sx={commonItemsStyling}
          inline
          getItemLabel={(index) => `#${index + 1}`}
          reOrderButtons={<FormItemButtons />}
          className="items-list"
        >
          <ReferenceInput
            label="Page"
            source="id"
            reference="journals-pages"
            validate={required()}
            fullWidth
          >
            <AutocompleteInput fullWidth validate={required()} />
          </ReferenceInput>
          <SelectInput
            source="timePeriod"
            validate={required()}
            choices={JournalTimePeriod}
            fullWidth
          />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
}
