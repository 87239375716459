import { CheckCircle, Error, Warning } from '@mui/icons-material';
import { Box, Card, CardContent, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { CustomDataProvider } from "src/auth";

import SystemStatus from "../components/SystemStatus";

export interface HealthCheckResponse {
    status: string;
    checks: Array<{ status: string, name: string, timestamp?: string }>;
  }

const Monitoring: FC<{ dataProvider: CustomDataProvider }> = ({ dataProvider }) => {
  const [isMongoOk, setIsMongoOk] = useState<boolean | null>(null);
  const [isPdfServiceOk, setIsPdfServiceOk] = useState<boolean | null>(null);
  const [isIdpServiceOk, setIsIdpServiceOk] = useState<boolean | null>(null);
  const [isWebAppServiceOk, setIsWebAppServiceOk] = useState<boolean | null>(null);
  const [isApiOk, setIsApiOk] = useState<boolean | null>(null);

  useEffect(() => {
    const fetchData = () => {
      const fetchDataAsync = async () => {
        try {
          const apiHealth = await dataProvider.healthCheck();
          setIsApiOk(apiHealth.status === 'UP');
          setIsMongoOk(apiHealth.checks[0].status === 'UP');
          setIsPdfServiceOk(apiHealth.checks[1].status === 'UP');
        } catch (error) {
          setIsMongoOk(false);
          setIsPdfServiceOk(false);
          setIsApiOk(false);
        }
  
        try {
          const webAppHealth = await dataProvider.healthCheck('app');
          setIsWebAppServiceOk(webAppHealth.status === 'UP');
        } catch (error) {
          setIsWebAppServiceOk(false);
        }
  
        try {
          const idpHealth = await dataProvider.healthCheck('idp');
          setIsIdpServiceOk(idpHealth.status === 'UP');
        } catch (error) {
          setIsIdpServiceOk(false);
        }
      };
  
      void fetchDataAsync();
    };
  
    fetchData();
  
    const interval = setInterval(fetchData, 60000);
  
    return () => clearInterval(interval);
  }, [dataProvider]);

  return (
    <Box>
      <Box sx={{ marginTop: 1.5, width: '100%' }}>
        <Card>
          <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
            {isMongoOk === null || isApiOk === null || isIdpServiceOk === null ? (
              <CircularProgress size={20} sx={{ color: 'blue' }} />
            ) : !isMongoOk || !isApiOk || !isIdpServiceOk ? (
              <>
                <Error sx={{ color: 'red' }} />
                <span style={{ paddingLeft: '5px', color: 'red' }}>Major outage</span>
              </>
            ) : !isPdfServiceOk || !isWebAppServiceOk ? (
              <>
                <Warning sx={{ color: 'orange' }} />
                <span style={{ paddingLeft: '5px', color: 'orange' }}>Partial outage</span>
              </>
            ) : (
              <>
                <CheckCircle sx={{ color: 'green', verticalAlign: 'middle' }} />
                <span style={{ color: 'green' }}>All systems are operational</span>
              </>
            )}
          </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ marginTop: 1.5, width: '100%' }}>
        <Card>
          <CardContent sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
              Status per service areas
            </Typography>
            <TableContainer style={{ marginTop: '2rem'}}>
              <Table>
                <TableHead>
                  <TableRow style={{ background: '#B9B9B9', fontWeight: 'bold'}}>
                    <TableCell>System</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <SystemStatus systemName="YBP-API" isOk={isApiOk} partialOutage={false} />
                  <SystemStatus systemName="MongoDB" isOk={isMongoOk} partialOutage={false}/>
                  <SystemStatus systemName="PDF Service" isOk={isPdfServiceOk} partialOutage={true} />
                  <SystemStatus systemName="YBP-IDP" isOk={isIdpServiceOk} partialOutage={false} />
                  <SystemStatus systemName="WEB App (MHP)" isOk={isWebAppServiceOk} partialOutage={true} />
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Monitoring;