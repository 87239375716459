import { FC, useContext } from "react";
import { DeleteButton, useRecordContext } from "react-admin";
import { AppRoleContext } from "src/context/AppRoleContext/AppRoleContext";

import { RemoveRequest } from "./types";

export const RemoveUserButton: FC = () => {
  const { role } = useContext(AppRoleContext);
  const { firstName, lastName, email } = useRecordContext<RemoveRequest>();

  return (
    <DeleteButton
      confirmTitle={
        firstName && lastName ? `${firstName} ${lastName}` : `${email}`
      }
      confirmContent={"Are you sure you want to delete this user?"}
      mutationMode="pessimistic"
      label="Remove"
      disabled={role !== "SUPERUSER"}
    />
  );
};
