import { useMemo } from "react";
import { useGetList } from "react-admin";

export const useRatingChart = (activityId: string, institutionId?: string) => {
  const { data = [], isLoading } = useGetList<{
    value: number;
    count: number;
    id: undefined;
  }>("statistics/in-progress-occurrences", {
    filter: {
      activityId,
      institutionId,
    },
  });

  const { x, y } = useMemo(() => {
    const x: Array<number> = [];
    const y: Array<number> = [];
    if (isLoading) {
      return {
        x,
        y,
      };
    }

    for (const dataPoint of data) {
      x.push(dataPoint.value);
      y.push(dataPoint.count);
    }

    return {
      x,
      y,
    };
  }, [data, isLoading]);

  const total = useMemo(() => y.reduce((prev, curr) => prev + curr, 0), [y]);

  return {
    x,
    y,
    total,
    isLoading,
  };
};
