import SendTimeExtensionIcon from "@mui/icons-material/SendTimeExtension";

import PathwayCreate from "./pathwayCreate";
import PathwayEdit from "./pathwayEdit";
import PathwayList from "./pathwayList";

export default {
  list: PathwayList,
  create: PathwayCreate,
  edit: PathwayEdit,
  icon: SendTimeExtensionIcon,
  recordRepresentation: (record: { name: string; role: string }) =>
    `${record.name} (${record.role})`
};
