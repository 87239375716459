import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import {
  Datagrid,
  DeleteButton,
  Edit,
  required,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useGetList,
} from "react-admin";
import { useParams } from "react-router-dom";

import { transformTeamDataBeforeRequest } from "../../transformDataBeforeRequest";
import { Practitioner } from "../../types";
import { commonDatagridStyling } from "../commonStyling";
import { AssignToTeamButton } from "./assignToTeamButton";
import { RemoveFromTeamButton } from "./removeFromTeamButton";

const UserEditToolbar = (props) => (
  <Toolbar sx={{ justifyContent: "space-between" }} {...props}>
    <SaveButton />
    <DeleteButton confirmTitle="Delete team" />
  </Toolbar>
);

const TeamInfoEdit = () => {
  const { institutionId, teamId } = useParams();
  const allPractitioners = useGetList<Practitioner>(
    `institutions/${institutionId}/practitioners`
  );
  const practitionersInTeam = useGetList<Practitioner>(
    `institutions/${institutionId}/teams/${teamId}/practitioners`
  );

  const practitionersOutsideOfTeam = useMemo(() => {
    if (!practitionersInTeam.data || !allPractitioners.data) return;

    const practitionersInTeamIds = practitionersInTeam.data.map(
      (practitioner) => practitioner.id
    );

    return allPractitioners.data.filter(
      (practitioner) => !practitionersInTeamIds.includes(practitioner.id)
    );
  }, [practitionersInTeam, allPractitioners]);

  return (
    <Box display="flex" flexDirection="column" gap="50px">
      <Edit
        title="Team"
        mutationMode="pessimistic"
        id={teamId}
        resource={`institutions/${institutionId}/teams`}
        transform={transformTeamDataBeforeRequest}
        redirect={false}
      >
        <SimpleForm toolbar={<UserEditToolbar />}>
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            gap="3%"
          >
            <TextInput source="name" validate={required()} fullWidth />
            <TextInput source="description" fullWidth />
          </Box>
        </SimpleForm>
      </Edit>

      <Box>
        <Typography>Practitioners in team</Typography>
        <Datagrid
          data={practitionersInTeam.data}
          total={practitionersInTeam.total}
          isLoading={practitionersInTeam.isLoading}
          bulkActionButtons={false}
          sort={{ field: "id", order: "DESC" }}
          sx={commonDatagridStyling}
        >
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="email" />
          <RemoveFromTeamButton />
        </Datagrid>
      </Box>

      <Box>
        <Typography>Practitioners outside of team</Typography>
        <Datagrid
          data={practitionersOutsideOfTeam}
          total={practitionersOutsideOfTeam?.length}
          isLoading={
            practitionersInTeam.isLoading || allPractitioners.isLoading
          }
          bulkActionButtons={false}
          sort={{ field: "id", order: "DESC" }}
          sx={commonDatagridStyling}
        >
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="email" />
          <AssignToTeamButton />
        </Datagrid>
      </Box>
    </Box>
  );
};

export default TeamInfoEdit;
