import { useMemo } from "react";
import { useGetList, useGetOne } from "react-admin";
import { Activity } from "src/activities/types";

export const useActivityRow = (
  id: string,
  institutionId?: string
) => {
  const { data, isLoading: isActivityLoading } = useGetOne<Activity>(
    "activities",
    { id },
    { staleTime: 86400 * 1000, refetchOnWindowFocus: false }
  );

  const { total: assignedTotal = 0, isLoading: isAssignedLoading } =
  useGetList<undefined>("statistics/assigned-occurrences", {
    filter: {
      activityId: id,
      institutionId,
    },
  });

  const { total: completedTotal = 0, isLoading: isCompletedLoading } =
    useGetList<undefined>("statistics/completed-occurrences", {
      filter: {
        activityId: id,
        institutionId,
      },
    });

  const { total: avgTimeSpent = 0, isLoading: isAvgTimeSpentLoading } =
    useGetList<undefined>("statistics/activity-avg-time-spent", {
      filter: {
        activityId: id,
        institutionId,
      },
    });

  const isLoading = useMemo(
    () => isActivityLoading || isCompletedLoading || isAvgTimeSpentLoading || isAssignedLoading,
    [isActivityLoading, isCompletedLoading, isAvgTimeSpentLoading, isAssignedLoading]
  );

  return {
    name: data?.title ?? "",
    isLoading,
    completionRate: assignedTotal
      ? `${((completedTotal / assignedTotal) * 100).toFixed(0)}%`
      : "-",
    avgTimeSpent: avgTimeSpent ? avgTimeSpent.toFixed(0) : "-",
  };
};
