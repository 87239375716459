import { TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { LoadingIndicator } from "react-admin";

import { useActivityRow } from "./hooks";

interface ActivityRowProps {
  id: string;
  count: number;
  institutionId?: string;
}

export const ActivityRow: FC<ActivityRowProps> = ({
  id,
  count,
  institutionId,
}) => {
  const { name, isLoading, completionRate, avgTimeSpent } = useActivityRow(
    id,
    institutionId
  );

  if (isLoading) {
    return (
      <TableRow>
        <TableCell colSpan={4}>
          <LoadingIndicator />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{count}</TableCell>
      <TableCell>{completionRate}</TableCell>
      <TableCell>{avgTimeSpent}</TableCell>
    </TableRow>
  );
};
