import { Pathway } from "./interfaces";


export const transformDataBeforeRequest = (data: Pathway) => {
  data.sidePaths = data.sidePaths.map(path => ({...path, timeRelation: data.mainPath.timeRelation}))

  if (!data.language) {
    data.language = undefined;
  }
  
  return data;
};
