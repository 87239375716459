import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { InfoCard } from "src/dashboard/components/InfoCard";
import { OccurrenceInfoTable } from "src/dashboard/components/OccurrenceInfoTable";

import { InProgressChart } from "./components/InProgressChart";
import { RatingChart } from "./components/RatingChart";
import { TimeSpentChart } from "./components/TimeSpentChart";

const ActivityStatistics = () => {
  const { id } = useParams();

  return (
    <Box>
      <Box sx={{ marginTop: 1.5, gap: 1 }} flexWrap="wrap" display="flex">
        <InfoCard
          resource="statistics/assigned-activities"
          label="Activity assigned in total"
          activityId={id}
        />
        <InfoCard
          resource="statistics/assigned-occurrences"
          label="Assigned occurrences in total"
          activityId={id}
        />
        <InfoCard
          resource="statistics/completed-occurrences"
          label="Completed occurrences in total"
          activityId={id}
        />
      </Box>
      <Box sx={{ marginTop: 1.5, gap: 1 }} flexWrap="wrap" display="flex">
        <Box>
          <OccurrenceInfoTable activityId={id} />
        </Box>
        <Box>
          <InProgressChart activityId={id} />
          <Box sx={{height: 8}}/>
          <TimeSpentChart activityId={id} />
        </Box>
        <Box>
          <RatingChart activityId={id} />
        </Box>
      </Box>
    </Box>
  );
};

export default ActivityStatistics;
