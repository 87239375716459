import { FC, useMemo } from "react";
import {
  ArrayInput,
  required,
  SelectInput,
  SimpleFormIterator
} from "react-admin";
import { commonAnswerOptionsListStyling } from "src/activities/commonStyling";
import {
  ActivityItem,
  ActivityItemType,
  ActivityScore
} from "src/activities/types";

interface CalculationItemsProps {
  getSource: (name: string) => string;
  items: Array<ActivityItem>;
  scores: Array<ActivityScore>;
  hasEvaluation?: boolean;
}

const getIndexFromSource = (source: string): number | undefined => {
  const index = source.split(".").find((key) => !isNaN(Number(key)));

  return typeof index === "string" ? parseInt(index) : undefined;
};

export const CalculationItems: FC<CalculationItemsProps> = ({
  getSource,
  items,
  scores,
  hasEvaluation
}) => {
  const index = useMemo(() => getIndexFromSource(getSource("")), [getSource]);
  const isArrayDisabled = useMemo(
    () => scores[index]?.isMainScore && hasEvaluation,
    [scores, index, hasEvaluation]
  );
  const choices = useMemo(
    () => [
      ...items
        .filter(
          (item) =>
            item.activityItemType ===
              ActivityItemType.PATIENT_RESPONSE_LIKERT_SCALE &&
            item.semanticIdentifier
        )
        .map((item) => ({
          id: item.semanticIdentifier,
          name:
            item.displayValue.length > 80
              ? item.displayValue.substring(0, 78).padEnd(80, ".")
              : item.displayValue
        })),
      ...scores
        .filter(
          (score, i) =>
            score.name &&
            score.semanticIdentifier &&
            typeof index === "number" &&
            index > i
        )
        .map((score) => ({
          id: score.semanticIdentifier,
          name: score.name
        }))
    ],
    [index, items, scores]
  );

  return (
    <ArrayInput
      sx={commonAnswerOptionsListStyling}
      required
      source={getSource("calculationItems")}
      fullWidth
      helperText="Only likert scale and other scores can be used for calculations"
      disabled={isArrayDisabled}
    >
      <SimpleFormIterator fullWidth getItemLabel={(index) => `${index + 1}.`}>
        <SelectInput
          source=""
          validate={required()}
          fullWidth
          label="Question to calculate"
          choices={choices}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
