import { useGetList } from "react-admin";

interface IUseInfoTable {
  isLoading: boolean;
  activities: Array<{ id: string, count: number }>;
}

export const useTopActivitiesInfoTable = (
  institutionId?: string
): IUseInfoTable => {
  const { data: activities = [], isLoading } = useGetList<{ id: string, count: number }>(
    "statistics/top-activities",
    {
      filter: { institutionId },
      pagination: {page: 1, perPage: 10}
    }
  );

  return {
    activities,
    isLoading,
  };
};
