import { FC, useContext } from "react";
import { Datagrid, List, Pagination, TextField } from "react-admin";
import { useParams } from "react-router-dom";
import { AppRoleContext } from "src/context/AppRoleContext/AppRoleContext";
import { commonDatagridStyling } from "src/institutions/InstitutionEdit/commonStyling";

const PractitionersStatisticList: FC = () => {
  const { id: institutionId } = useParams();
  const { role } = useContext(AppRoleContext);

  return (
    <List
      resource={`institutions/${institutionId}/practitioners`}
      pagination={<Pagination />}
      title={" "}
      exporter={false}
      sx={{ width: "100%" }}
    >
      <Datagrid
        bulkActionButtons={false}
        sx={commonDatagridStyling}
        rowClick={(id) => `practitioners/${id}`}
      >
        {role !== "SUPERUSER" ? (
          <TextField source="id" sortable={false} />
        ) : null}
        {role === "SUPERUSER" ? (
          <TextField source="firstName" sortable={false} />
        ) : null}
        {role === "SUPERUSER" ? (
          <TextField source="lastName" sortable={false} />
        ) : null}
        {role === "SUPERUSER" ? (
          <TextField source="email" sortable={false} />
        ) : null}
      </Datagrid>
    </List>
  );
};

export default PractitionersStatisticList;
