import { Box, Typography } from "@mui/material";
import { FC } from "react";
import StatisticItem from "src/components/StatisticItem";

import { containerStyles } from "./commonStyling";

interface StatisticsProps {
  resource?: string;
  onlyTotal?: boolean;
}

export const Statistics: FC<StatisticsProps> = ({ resource, onlyTotal }) => {
  return (
    <Box sx={containerStyles}>
      <Typography variant="h6">Statistics</Typography>
        <StatisticItem 
          title="Total" 
          resource={resource || ''} 
        />
      {!onlyTotal && (
        <>
          <StatisticItem
            title="Active"
            resource={resource}
            filter={{ isTesting: false }}
          />
          <StatisticItem
            title="Testing"
            resource={resource}
            filter={{ isTesting: true }}
          />
        </>
      )}
    </Box>
  );
};
