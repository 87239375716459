import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { LoadingIndicator } from "react-admin";

import { ActivityRow } from "./ActivityRow";
import { useTopActivitiesInfoTable } from "./hooks";
interface TopActivitiesInfoTableProps {
  institutionId?: string;
}

export const TopActivitiesInfoTable: FC<TopActivitiesInfoTableProps> = ({
  institutionId,
}) => {
  const { isLoading, activities } = useTopActivitiesInfoTable(institutionId);

  if (isLoading) {
    return (
      <Card sx={{ minWidth: 560 }}>
        <CardContent sx={{ display: "flex", justifyContent: "center" }}>
          <LoadingIndicator />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ width: 560 }}>
      <CardContent sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h5" sx={{ color: "#4F4F4F", textAlign: "left" }}>
          {"Top 10 assigned activities"}
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow style={{ backgroundColor: "#B9B9B9" }}>
                <TableCell sx={{ width: "33%" }}>{"Activity"}</TableCell>
                <TableCell>{"Assigned, times"}</TableCell>
                <TableCell>{"Completion rate, %"}</TableCell>
                <TableCell>{"Average completion time"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activities.map(({ id, count }) => (
                <ActivityRow
                  id={id}
                  key={id}
                  count={count}
                  institutionId={institutionId}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
