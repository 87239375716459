import { Card } from "@mui/material";
import { FC } from "react";
import { Datagrid, DateField, Labeled, List, TextField } from "react-admin";

import { UDI } from "./types";

const UDIPanel: FC = () => (
  <Card sx={{ padding: 2 }}>
    <Labeled sx={{ padding: 1 }}>
      <TextField source="mobileVersion" />
    </Labeled>
    <Labeled sx={{ padding: 1 }}>
      <TextField source="webVersion" />
    </Labeled>
    <Labeled sx={{ padding: 1 }}>
      <TextField source="apiVersion" />
    </Labeled>
    <Labeled sx={{ padding: 1 }}>
      <TextField source="idpVersion" />
    </Labeled>
    <Labeled sx={{ padding: 1 }}>
      <TextField source="adminPanelVersion" />
    </Labeled>
  </Card>
);

const ListUDI: FC = () => (
  <List<UDI> sort={{ field: "createdAt", order: "DESC" }}>
    <Datagrid rowClick="edit" bulkActionButtons={false} expand={UDIPanel}>
      <TextField source="udiPi" sortable={false} />
      <TextField source="status" sortable={false} />
      <DateField source="expiresAt" emptyText="-" />
    </Datagrid>
  </List>
);

export default ListUDI;
