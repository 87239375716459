import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

export const containerStyles: SxProps = {
  width: 120,
  margin: "4em 1em",
};

export const statisticsTypography: SxProps<Theme> = {
  variant: "body2",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};