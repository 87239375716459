import { Box, Tab, Tabs } from "@mui/material";
import { FC, useState } from "react";
import { useDataProvider } from 'react-admin';
import { CustomDataProvider } from 'src/auth';

import Monitoring from "./subtabs/monitoring";
import Statistics from "./subtabs/statistics";


const Dashboard: FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const dataProvider = useDataProvider<CustomDataProvider>();

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  
  return (
    <Box>
      <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="dashboard tabs">
        <Tab label="Monitoring" />
        <Tab label="Statistics" />
      </Tabs>
      {tabIndex === 0 && <Monitoring dataProvider={dataProvider} />}
      {tabIndex === 1 && <Statistics />}
    </Box>
  );
};

export default Dashboard;