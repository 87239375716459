export const colorChoices = [
  { id: "#D8E7B9", name: "Main/Light Green: #D8E7B9" },
  { id: "#ADCC9C", name: "Main/Green: #ADCC9C" },
  { id: "#A5BBA9", name: "Additional/Green: #A5BBA9" },
  { id: "#778C9D", name: "Main/Blue: #778C9D" },
  { id: "#798597", name: "Additional/Blue: #798597" },
  { id: "#968491", name: "Main/Purple: #968491" },
  { id: "#AC7E86", name: "Additional/Purple: #AC7E86" },
  { id: "#C47472", name: "Main/Red: #C47472" },
  { id: "#C55D62", name: "Additional/Red: #C55D62" },
  { id: "#AE464B", name: "Additional/Dark Red: #AE464B" },
];
