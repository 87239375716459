import { FC, useMemo } from "react";
import { BooleanInput } from "react-admin";
import { useWatch } from "react-hook-form";

import {
  Activity,
  ActivityScore,
  EvaluationConfig
} from "../../types";
import { EvaluationForm } from "./evaluationForm";

const ActivityEvaluation: FC = () => {
  const scores = useWatch<Activity>({ name: "scores" }) as
    | Array<ActivityScore>
    | undefined;

  const evaluation = useWatch<Activity>({ name: "evaluation" }) as
    | EvaluationConfig
    | undefined;

  const mainScore = useMemo(
    () => scores?.find((score) => score?.isMainScore),
    [scores]
  );
  return (
    <>
      <BooleanInput
        source="isEvaluationEnabled"
        disabled={!mainScore}
        defaultValue={!!evaluation}
        helperText="To enable evaluation you need to configure the Main Score in the SCORING tab"
      />
      <EvaluationForm />
    </>
  );
};

export default ActivityEvaluation;
