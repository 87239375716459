import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { FC, useMemo, useState } from "react";
import {
  Button as ReactAdminButton,
  useDataProvider,
  useGetList,
  useGetOne,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useMutation } from "react-query";

import { CustomDataProvider } from "../../../auth";
import { Institution, Practitioner } from "../../types";
import { RoleChoices } from "./roleChoices";

export const AssignToInstitutionButton: FC<{
  institutionId: string;
  resource: string;
}> = ({ institutionId, resource }) => {
  const { id } = useRecordContext<Practitioner>();
  const { data, isLoading } = useGetList<Practitioner>(resource);
  const dataProvider = useDataProvider<CustomDataProvider>();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [roles, setRoles] = useState<Array<string>>([]);

  const { mutateAsync, isLoading: isMutating } = useMutation(() =>
    dataProvider.assignPractitionerToInstitution(institutionId, id, roles)
  );

  const institution = useGetOne<Institution>(
    `institutions`, { id: institutionId }
  ).data;

  const handleConfirm = async () => {
    await mutateAsync();
    setOpen(false);
    setRoles([]);
    refresh();
  };
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const isAlreadyAssigned = useMemo(() => {
    return data?.some((practitioner) => practitioner.id === id);
  }, [data, id]);

  return (
    <>
      {isAlreadyAssigned ? (
        <Typography variant="body2">Practitioner already assigned</Typography>
      ) : (
        <>
          <ReactAdminButton
            label="Assign"
            onClick={(e) => {
              e.stopPropagation();
              handleClick();
            }}
            disabled={isLoading || isMutating}
          />
          <Dialog
            open={open}
            onClose={handleDialogClose}
            onClick={(e) => e.stopPropagation()}
          >
            <DialogTitle>{`Assign to institution ${institution?.name}`}</DialogTitle>
            <DialogContent>
              <FormControl fullWidth>
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                  label="Role"
                  labelId="role-select-label"
                  value={roles}
                  multiple={true}
                  onChange={(e) => setRoles(e.target.value as Array<string>)}
                >
                  {RoleChoices.map((choice, index) => (
                    <MenuItem key={index} value={choice.id}>
                      {choice.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDialogClose();
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  void handleConfirm();
                }}
                disabled={roles.length === 0}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};
