import { useContext } from "react";
import {
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  List,
  ReferenceArrayField,
  SearchInput,
  SingleFieldList,
  TextField,
} from "react-admin";
import { Statistics } from "src/common/statistics";
import { AppRoleContext } from "src/context/AppRoleContext/AppRoleContext";

const PatientsList = () => {
  const { role } = useContext(AppRoleContext);

  return (
    <List
      title="Patients"
      queryOptions={{ retry: 2 }}
      aside={<Statistics />}
      filters={[
        <SearchInput
          source="q"
          alwaysOn
          key="search-input"
          disabled={role === "CONTENT_CREATOR"}
          helperText="First name, last name, email"
        />,
      ]}
    >
      <Datagrid
        rowClick={role === "SUPERUSER" ? "edit" : () => false}
        bulkActionButtons={false}
      >
        <TextField
          source="firstName"
          display={role === "SUPERUSER" ? "inherit" : "none"}
        />
        <TextField
          source="lastName"
          display={role === "SUPERUSER" ? "inherit" : "none"}
        />
        <TextField
          source="email"
          display={role === "SUPERUSER" ? "inherit" : "none"}
        />
        <ReferenceArrayField
          label="Institutions"
          reference="institutions"
          source="institutionIds"
          emptyText="-"
          sx={{ minHeight: 35 }}
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField source="lastLogin" emptyText="-" fullWidth showTime />
        <BooleanField source="isTesting" label="Testing" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default PatientsList;
