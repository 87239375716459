import parsePhoneNumberFromString from "libphonenumber-js";

export const phoneNumberValidation = (value: string | undefined) => {
  if (value == undefined) return;

  const parsedPhoneNumber = parsePhoneNumberFromString(value);

  if (
    parsedPhoneNumber === undefined ||
    parsedPhoneNumber.country === undefined
  ) {
    return "Invalid phone number";
  }

  return;
};
