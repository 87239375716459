import { FC } from "react";
import { minValue, required, TextInput } from "react-admin";
import { useWatch } from "react-hook-form";
import { CustomNumberInput } from "src/components/CustomNumberInput";
import { Journal } from "src/journals/types";

export const IntroductionForm: FC = () => {
  const addIntroduction = useWatch<Journal>({
    name: "addIntroduction",
  }) as boolean | undefined;

  if (!addIntroduction) {
    return null;
  }
  return (
    <>
      <TextInput source="introduction.title" fullWidth validate={required()} />
      <TextInput
        source="introduction.description"
        fullWidth
        multiline
        validate={required()}
      />
      <CustomNumberInput
        source="introduction.suggestedDuration"
        validate={minValue(0)}
        fullWidth
      />
      <CustomNumberInput
        source="introduction.version"
        fullWidth
        validate={[minValue(1), required()]}
      />
    </>
  );
};
