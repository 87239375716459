import BusinessIcon from "@mui/icons-material/Business";
import { Resource } from "react-admin";
import { Route } from "react-router-dom";

import InstitutionCreate from "./InstitutionCreate";
import InstitutionInfoEdit from "./InstitutionEdit/infoEdit";
import PatientsOnboardingList from "./InstitutionEdit/onboarding/onboardingList";
import InstitutionPatientEdit from "./InstitutionEdit/patients/patientEdit";
import InstitutionPatientsList from "./InstitutionEdit/patients/patientList";
import InstitutionPractitionerEdit from "./InstitutionEdit/practitioners/practitionerEdit";
import InstitutionPractitionersList from "./InstitutionEdit/practitioners/practitionersList";
import TeamInfoEdit from "./InstitutionEdit/teams/teamEdit";
import TeamCreate from "./InstitutionEdit/teams/teamsCreate";
import InstitutionTeamsList from "./InstitutionEdit/teams/teamsList";
import InstitutionList from "./InstitutionList";
import InstitutionShow from "./InstitutionShow";
import PractitionerStatistics from "./InstitutionShow/PractitionerStatistics";
import { ListedInstitution } from "./types";

export default (
  <Resource
    name="institutions"
    list={InstitutionList}
    edit={InstitutionInfoEdit}
    create={InstitutionCreate}
    show={InstitutionShow}
    icon={BusinessIcon}
    recordRepresentation={(record: ListedInstitution) => `${record.name}`}
  >
    <Route path=":institutionId/teams" element={<InstitutionTeamsList />} />
    <Route path=":institutionId/teams/create" element={<TeamCreate />} />
    <Route path=":institutionId/teams/:teamId" element={<TeamInfoEdit />} />
    <Route
      path=":institutionId/practitioners"
      element={<InstitutionPractitionersList />}
    />
    <Route
      path=":institutionId/practitioners/:practitionerId"
      element={<InstitutionPractitionerEdit />}
    />
    <Route
      path=":institutionId/patients"
      element={<InstitutionPatientsList />}
    />
    <Route
      path=":institutionId/patients/:patientId"
      element={<InstitutionPatientEdit />}
    />
    <Route
      path=":institutionId/onboarding"
      element={<PatientsOnboardingList />}
    />
    <Route
      path=":institutionId/show/practitioners/:practitionerId"
      element={<PractitionerStatistics />}
    />
  </Resource>
);
