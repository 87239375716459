import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";

import PatientsCreate from "./patientsCreate";
import PatientsEdit from "./patientsEdit";
import PatientsList from "./patientsList";

export default {
  list: PatientsList,
  create: PatientsCreate,
  edit: PatientsEdit,
  icon: PsychologyAltIcon,
  recordRepresentation: (record: { name: string; role: string }) =>
    `${record.name} (${record.role})`,
  options: { label: "Patients" },
};
