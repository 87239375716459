import { FC } from "react";
import { Navigate, useParams } from "react-router-dom";

interface Props {
  tabToRedirectIndex: number;
}

export const RedirectToInstitutions: FC<Props> = ({ tabToRedirectIndex }) => {
  const { institutionId } = useParams();

  return (
    <Navigate
      to={`/institutions/${institutionId}`}
      state={{ tabIndex: tabToRedirectIndex }}
    />
  );
};
