import { Box, Typography } from "@mui/material";
import { FC } from "react";
import {
  Create,
  LoadingIndicator,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetList,
} from "react-admin";

import { createChoices, versionStringValidator } from "./constans";
import { UDI, UDIStatus } from "./types";

const CreateUDI: FC = () => {
  const {
    data: [defaultValues],
    isFetchedAfterMount,
  } = useGetList<UDI>(
    "device-identifiers",
    {
      filter: {
        status: UDIStatus.ACTIVE,
      },
      pagination: { page: 1, perPage: 2 },
      sort: { field: "createdAt", order: "DESC" },
    },
    { initialData: { data: [] }, refetchInterval: false }
  );

  if (!isFetchedAfterMount) {
    return (
      <Box>
        <LoadingIndicator />
      </Box>
    );
  }

  return (
    <Create<UDI> title="UDI">
      <SimpleForm
        defaultValues={
          defaultValues
            ? {
                udiDi: defaultValues.udiDi,
                mobileVersion: defaultValues.mobileVersion,
                webVersion: defaultValues.webVersion,
                apiVersion: defaultValues.apiVersion,
                adminPanelVersion: defaultValues.adminPanelVersion,
                idpVersion: defaultValues.idpVersion,
              }
            : undefined
        }
      >
        <Typography variant="h5">UDI configuration</Typography>
        <TextInput source="udiDi" label="UDI-DI" validate={[required()]} />
        <TextInput source="udiPi" label="UDI-PI" validate={[required()]} />
        <Typography variant="h5">Version configuration</Typography>
        <TextInput source="mobileVersion" validate={versionStringValidator} />
        <TextInput source="webVersion" validate={versionStringValidator} />
        <TextInput source="apiVersion" validate={versionStringValidator} />
        <TextInput
          source="adminPanelVersion"
          validate={versionStringValidator}
        />
        <TextInput source="idpVersion" validate={versionStringValidator} />
        <Typography variant="h5">Additional configuration</Typography>
        <SelectInput
          source="status"
          choices={createChoices}
          validate={[required()]}
          defaultValue={UDIStatus.PROPOSED}
        />
      </SimpleForm>
    </Create>
  );
};

export default CreateUDI;
