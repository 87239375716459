import { Box } from "@mui/material";
import {
  BooleanInput,
  DateField,
  Edit,
  Labeled,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";

import { useMutationErrorNotify } from "../../common/handleMutationError";
import { phoneNumberValidation } from "../../common/phoneNumberValidation";
import { CountryChoices } from "../countryChoices";
import { transformInstitutionDataBeforeRequest } from "../transformDataBeforeRequest";
import InstitutionEdit from ".";

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const InstitutionInfoEdit = () => {
  const notify = useMutationErrorNotify();

  return (
    <InstitutionEdit>
      <Edit
        title="Institution"
        transform={transformInstitutionDataBeforeRequest}
        mutationMode="pessimistic"
        mutationOptions={{
          onError: notify,
        }}
      >
        <SimpleForm toolbar={<UserEditToolbar />}>
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            gap="3%"
          >
            <TextInput source="name" validate={required()} fullWidth />
            <TextInput
              source="phoneNumber"
              validate={phoneNumberValidation}
              fullWidth
            />
            <TextInput type="email" source="email" fullWidth />
            <TextInput type="url" source="webpage" fullWidth />
            <TextInput source="addressLine1" fullWidth />
            <TextInput source="addressLine2" fullWidth />
            <Box display="flex" flexDirection="row" gap="3%">
              <TextInput source="city" fullWidth />
              <TextInput source="state" fullWidth />
              <TextInput source="postalCode" fullWidth />
            </Box>
            <SelectInput source="country" choices={CountryChoices} fullWidth />
            <BooleanInput source="isTesting" />
            <Box display="flex" flexDirection="row" gap="3%">
              <Labeled source="createdAt">
                <DateField source="createdAt" showTime />
              </Labeled>
              <Labeled source="updatedAt">
                <DateField source="updatedAt" showTime />
              </Labeled>
            </Box>
          </Box>
        </SimpleForm>
      </Edit>
    </InstitutionEdit>
  );
};

export default InstitutionInfoEdit;
