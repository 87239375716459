import { ActivityModule } from "../types";

export const activityModuleChoices = [
  {
    group: 'Depression',
    items: [
      {
        id: ActivityModule.BEHAVIOR_ACTIVATION,
        name: "Behavior activation",
      },
      {
        id: ActivityModule.COMPLETION,
        name: "Completion",
      },
      {
        id: ActivityModule.NEEDS_AND_VALUES,
        name: "Needs and values",
      },
      {
        id: ActivityModule.PREPARATION,
        name: "Preparation",
      },
      {
        id: ActivityModule.PSYCHOEDUCATION_DEPRESSION,
        name: "Psychoeducation Depression",
      },
      {
        id: ActivityModule.RETHINKING,
        name: "Rethinking",
      },
      {
        id: ActivityModule.TREATMENT_PLAN,
        name: "Treatment plan",
      },
    ],
  },
  {
    group: 'Anxiety',
    items: [
      {
        id: ActivityModule.PSYCHOEDUCATION_ANXIETY,
        name: "Psychoeducation Anxiety",
      },
      {
        id: ActivityModule.TENSION_AND_RELAXATION,
        name: "Tension and Relaxation",
      },
      {
        id: ActivityModule.CONFRONTATION,
        name: "Confrontation",
      },
      {
        id: ActivityModule.PANIC_DISORDER,
        name: "Panic disorder",
      },
      {
        id: ActivityModule.AGORAPHOBIA,
        name: "Agoraphobia",
      },
      {
        id: ActivityModule.GENERALIZED_ANXIETY_DISORDER,
        name: "Generalized anxiety disorder",
      },
      {
        id: ActivityModule.SOCIAL_ANXIETY_DISORDER,
        name: "Social anxiety disorder",
      },
    ],
  },
];