import { Datagrid, List, TextField } from "react-admin";
import { StatisticsByStatus } from "src/common/statisticsByStatus";

const JournalList = () => (
  <List aside={<StatisticsByStatus />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="title" />
      <TextField source="status" />
    </Datagrid>
  </List>
);

export default JournalList;
