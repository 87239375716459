import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Typography,
} from "@mui/material";
import { FC, useContext, useState } from "react";
import {
  Button as ReactAdminButton,
  Datagrid,
  DateField,
  FunctionField,
  List,
  Pagination,
  TextField,
  Title,
  useFilterState,
  useGetList,
  usePaginationState,
} from "react-admin";
import { Link, useLocation } from "react-router-dom";
import { Statistics } from "src/common/statistics";
import SearchBar from "src/components/SearchBar";
import { AppRoleContext } from "src/context/AppRoleContext/AppRoleContext";
import { Practitioner } from "src/institutions/types";

import InstitutionEdit from "..";
import { commonDatagridStyling } from "../commonStyling";
import { AssignToInstitutionButton } from "./assignToInstitutionButton";
import { sortingButton } from "./commonStyling";
import { RemoveFromInstitutionButton } from "./removeFromInstitutionButton";

const CustomCreateButton = ({
  basePath,
  institutionId,
}: {
  basePath: string;
  institutionId?: string;
}) => (
  <ReactAdminButton
    component={Link}
    startIcon={<AddIcon />}
    to={`${basePath}/create?institutionId=${institutionId}`}
    label="Create"
  />
);

const InstitutionPractitionersList: FC = () => {
  const { role } = useContext(AppRoleContext);
  const { pathname } = useLocation();
  const institutionId = pathname.split("/")[2] || "";
  const paginationForPractitionersInInstitution = usePaginationState({ perPage: 5 });
  const paginationForAllPractitioners = usePaginationState({ perPage: 5 });
  const { filter, setFilter } = useFilterState({});
  const [searchText, setSearchText] = useState("");
  const [sortInstitution, setSortInstitution] = useState({ field: 'isAdmin', order: 'DESC' });

  const allPractitioners = useGetList("users/practitioners", {
    pagination: paginationForAllPractitioners,
    filter,
  });
  const practitionersInInstitution = useGetList(
    `institutions/${institutionId}/practitioners`,
    { pagination: paginationForPractitionersInInstitution, sort: sortInstitution }
  );

  const handleSortChangeInstitution = () => {
    setSortInstitution((prev) => ({
      field: 'isAdmin',
      order: prev.field === 'isAdmin' && prev.order === 'ASC' ? 'DESC' : 'ASC',
    }));
  };

  const handleFilter = () => {
    setFilter(searchText);
  };

  const handleClearFilters = () => {
    setFilter('');
    setSearchText("");
  };

  const handleRemoveSuccess = () => {
    if (practitionersInInstitution.data.length === 1 && paginationForPractitionersInInstitution.page > 1) {
      paginationForPractitionersInInstitution.setPage(paginationForPractitionersInInstitution.page - 1);
    } else {
      void practitionersInInstitution.refetch();
    }
  };

  return (
    <InstitutionEdit>
      <Box display="flex" flexDirection="column" gap="50px">
        <Title title="Practitioners" />
        <Box>
          <List
            resource={`institutions/${institutionId}/practitioners`}
            pagination={false}
            aside={<Statistics resource={`institutions/${institutionId}/practitioners`} />}
            title={" "}
            exporter={false}
            actions={
              role === "SUPERUSER" && (
                <CustomCreateButton
                  basePath={`/users/practitioners`}
                  institutionId={institutionId}
                />
              )
            }
          >
            {role === "SUPERUSER" ? (
              <Box>
                <Datagrid
                  bulkActionButtons={false}
                  sx={commonDatagridStyling}
                  rowClick={(id) => `${id}`}
                  {...practitionersInInstitution}
                >
                  <TextField source="firstName" sortable={false} />
                  <TextField source="lastName" sortable={false} />
                  <TextField source="email" sortable={false} />
                  <DateField source="lastLogin" emptyText="-" fullWidth showTime sortable={false} />
                  <FunctionField
                    label={
                      <Box onClick={handleSortChangeInstitution} sx={sortingButton}>
                        User Role
                      </Box>
                    }
                    render={(record: Practitioner) => {
                      return record.isAdmin ? (
                        <div>ADMIN</div>
                      ) : (
                        <div>MHP</div>
                      );
                    }}
                  />
                  <RemoveFromInstitutionButton onRemoveSuccess={handleRemoveSuccess} />
                </Datagrid>
                <Pagination
                  {...paginationForPractitionersInInstitution} total={practitionersInInstitution.total}
                />
              </Box>
            ) : (
              <div />
            )}
          </List>
        </Box>
        {role === "SUPERUSER" ? (
          <Box>
            <Typography
              sx={{
                padding: 2,
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              All Practitioners
            </Typography>
            <SearchBar
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={handleFilter}
              onClear={handleClearFilters}
            />
            <Datagrid
              bulkActionButtons={false}
              sx={commonDatagridStyling}
              sort={{ field: "id", order: "DESC" }}
              {...allPractitioners}
            >
              <TextField source="firstName" />
              <TextField source="lastName" />
              <TextField source="email" />
              <AssignToInstitutionButton
                institutionId={institutionId}
                resource={`institutions/${institutionId}/practitioners`}
              />
            </Datagrid>
            <Pagination {...paginationForAllPractitioners} total={allPractitioners.total} />
          </Box>
        ) : null}
      </Box>
    </InstitutionEdit>
  );
};

export default InstitutionPractitionersList;