import { Box } from "@mui/material";
import {
  BooleanInput,
  Create,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

import { useMutationErrorNotify } from "../common/handleMutationError";
import { phoneNumberValidation } from "../common/phoneNumberValidation";
import { CountryChoices } from "./countryChoices";

const InstitutionCreate = () => {
  const notify = useMutationErrorNotify();

  return (
    <Create
      title="Institution"
      mutationOptions={{
        onError: notify,
      }}
    >
      <SimpleForm>
        <Box
          display="flex"
          flexDirection="column"
          width={{ xs: "100%", xl: 1414 }}
          gap="3%"
        >
          <TextInput source="name" validate={required()} fullWidth />
          <TextInput
            source="phoneNumber"
            validate={phoneNumberValidation}
            fullWidth
          />
          <TextInput type="email" source="email" fullWidth />
          <TextInput type="url" source="webpage" fullWidth />
          <TextInput source="addressLine1" fullWidth />
          <TextInput source="addressLine2" fullWidth />
          <Box display="flex" flexDirection="row" gap="3%">
            <TextInput source="city" fullWidth />
            <TextInput source="state" fullWidth />
            <TextInput source="postalCode" fullWidth />
          </Box>
          <SelectInput source="country" choices={CountryChoices} fullWidth />
          <BooleanInput
            source="isTesting"
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default InstitutionCreate;
