import { FC, useEffect, useRef } from "react";
import { NumberInput, NumberInputProps } from "react-admin";

/**
 * Drop-in replacement for react-admin's Number Input
 * It disables value change on scroll
 * It allows only integers
 * @param props NumberInputProps
 * @returns NumberInput
 */
export const CustomNumberInput: FC<NumberInputProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (document.activeElement === inputRef.current) {
        event.preventDefault();
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      const { key } = event;

      if (key === "e" || key === "." || key === "," || key === "-" || key === "+") {
        event.preventDefault();
      }
    };

    const inputElement = inputRef.current;

    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel);
      inputElement.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheel);
        inputElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  return (
    <NumberInput
      {...props}
      min={0}
      inputRef={inputRef}
    />
  );
};
