import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button as MuiButton, TextField as MuiTextField } from '@mui/material';
import React from 'react';

import { searchBarContainer, searchButton } from './commonStyling';

interface SearchBarProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch: () => void;
  onClear: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ value, onChange, onSearch, onClear }) => {
  return (
    <Box sx={searchBarContainer}>
      <MuiTextField
        value={value}
        onChange={onChange}
        variant="filled"
        label="Search"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSearch();
          }
        }}
      />
      <MuiButton
        variant="outlined"
        onClick={onSearch}
        sx={searchButton}
      >
        Search
      </MuiButton>
      <MuiButton
        variant="outlined"
        onClick={onClear}
        sx={searchButton}
        startIcon={<ClearIcon />}
      >
        Clear
      </MuiButton>
    </Box>
  );
};

export default SearchBar;