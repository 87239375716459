import { FC } from "react";
import {
  ArrayInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import {
  commonAnswerOptionsListStyling,
  commonStringArrayOptionsStyling,
} from "src/activities/commonStyling";
import { IndicationChoices } from "src/activities/selectChoices/indicationChoices";
import { LanguageChoices } from "src/activities/selectChoices/languageChoices";
import { StatusChoices } from "src/activities/selectChoices/statusChoices";

export const PathwayOverview: FC = () => (
  <>
    <TextInput source="title" validate={required()} fullWidth />
    <TextInput source="practitionersInstruction" fullWidth multiline />
    <ArrayInput source="indication" sx={commonAnswerOptionsListStyling}>
      <SimpleFormIterator sx={commonStringArrayOptionsStyling} fullWidth>
        <SelectInput
          source=""
          validate={required()}
          fullWidth
          label="Indication Name"
          choices={IndicationChoices}
        />
      </SimpleFormIterator>
    </ArrayInput>
    <TextInput source="contraIndication" fullWidth multiline />
    <SelectInput
      source="status"
      validate={required()}
      choices={StatusChoices}
      fullWidth
    />
    <SelectInput
      source="language"
      validate={required()}
      choices={LanguageChoices}
      fullWidth
    />
    <ReferenceArrayInput
      label="Applies to institutions"
      source="institutionIds"
      reference="institutions"
    >
      <AutocompleteArrayInput fullWidth />
    </ReferenceArrayInput>
  </>
);
