import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { Count } from 'react-admin';
import { statisticsTypography } from 'src/common/commonStyling';

interface StatisticItemProps {
  title: string;
  resource: string;
  filter?: object;
}

const StatisticItem: FC<StatisticItemProps> = ({ title, resource, filter }) => (
  <Typography sx={statisticsTypography}>
    <span>{title}:</span>
    <span>
      <Count resource={resource} filter={filter} />
    </span>
  </Typography>
);

export default StatisticItem;