import { Box, Card, CardContent, Typography } from "@mui/material";
import { FC, lazy, Suspense } from "react";
import { LoadingIndicator } from "react-admin";

import { useRatingChart } from "./hooks";

const Chart = lazy(() => import("../GenericChart"));

export const InProgressChart: FC<{
  institutionId?: string;
  activityId: string;
}> = ({ activityId, institutionId }) => {
  const { x, y, total, isLoading } = useRatingChart(activityId, institutionId);

  return (
    <Card sx={{ width: 560 }} raised>
      <CardContent sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h5"
              sx={{ color: "#4F4F4F", textAlign: "left" }}
            >
              {"Occurrences in progress"}
            </Typography>
          </Box>
          <Box sx={{ textAlign: "right" }}>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <Typography variant="h6">{total}</Typography>
            )}
          </Box>
        </Box>
        <Suspense fallback={<LoadingIndicator />}>
          <Chart x={x} y={y} title="In progress since (days)" />
        </Suspense>
      </CardContent>
    </Card>
  );
};
