import { useContext } from 'react';
import { Datagrid, DateField, FunctionField, List, Pagination } from 'react-admin';
import { useParams } from "react-router-dom";
import { NoValue } from 'src/components/NoValue';
import { UserInfo } from 'src/components/UserInfo';
import { AppRoleContext } from 'src/context/AppRoleContext/AppRoleContext';

const PolicyShow = () => {
  const { role } = useContext(AppRoleContext);
  const { id } = useParams();

  return (
    <List
      resource={`policies/${id}/users`}
      perPage={10}
      pagination={<Pagination />}
      sort={{ field: 'consentDate', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          label="User"
          render={(record) => {
            if (!record) {
              return <NoValue />;
            }

            return role === "SUPERUSER" ? (
              <UserInfo source="id" />
            ) : (
              <NoValue />
            );
          }}
        />
        <DateField showTime source="consentDate" />
        <DateField showTime source="rejectedAt" />
        <DateField showTime source="revokedAt" />
      </Datagrid>
    </List>
  );
};
  
export default PolicyShow;