import EventNoteIcon from '@mui/icons-material/EventNote';

import JournalCreate from './journalCreate';
import JournalEdit from './journalEdit';
import JournalList from "./journalList";
import { Journal } from "./types";

export default {
  list: JournalList,
  edit: JournalEdit,
  create: JournalCreate,
  icon: EventNoteIcon,
  recordRepresentation: (record: Journal) =>
    `${record.title}${record.version ? `, version: ${record.version}` : ""}, ${
      record.status
    }`
};
