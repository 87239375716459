import { Box, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { Datagrid, TextField, Title, useGetList } from "react-admin";
import { useParams } from "react-router-dom";

import { Practitioner } from "../../../institutions/types";
import { commonDatagridStyling } from "../commonStyling";
import { AssignToPractitionerButton } from "./assignToPractitionerButton";
import { RemoveFromPractitionerButton } from "./removeFromPractitionerButton";

const InstitutionPatientEdit: FC = () => {
  const { institutionId, patientId } = useParams();

  const allPractitionersInInstitution = useGetList<Practitioner>(
    `institutions/${institutionId}/practitioners`
  );
  const patientsPractitioners = useGetList<Practitioner>(
    `institutions/${institutionId}/patients/${patientId}/practitioners`
  );

  const notPatientsPractitioners = useMemo(() => {
    if (!allPractitionersInInstitution.data || !patientsPractitioners.data)
      return;

    const patientsPractitionersIds = patientsPractitioners.data.map(
      (practitioner) => practitioner.id
    );

    return allPractitionersInInstitution.data.filter(
      (practitioner) => !patientsPractitionersIds.includes(practitioner.id)
    );
  }, [allPractitionersInInstitution, patientsPractitioners]);

  return (
    <Box display="flex" flexDirection="column" paddingTop="50px" gap="50px">
      <Title title="Patients" />
      <Box>
        <Typography>{"Patient's practitioners"}</Typography>
        <Datagrid
          data={patientsPractitioners.data}
          total={patientsPractitioners.total}
          isLoading={patientsPractitioners.isLoading}
          bulkActionButtons={false}
          sort={{ field: "id", order: "DESC" }}
          sx={commonDatagridStyling}
        >
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="email" />
          <RemoveFromPractitionerButton />
        </Datagrid>
      </Box>

      <Box>
        <Typography>{"Not patient's practitioners"}</Typography>
        <Datagrid
          data={notPatientsPractitioners}
          total={notPatientsPractitioners?.length}
          isLoading={
            patientsPractitioners.isLoading ||
            allPractitionersInInstitution.isLoading
          }
          bulkActionButtons={false}
          sort={{ field: "id", order: "DESC" }}
          sx={commonDatagridStyling}
        >
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="email" />
          <AssignToPractitionerButton />
        </Datagrid>
      </Box>
    </Box>
  );
};

export default InstitutionPatientEdit;
