import { Box, Typography } from "@mui/material";
import { FC } from "react";
import {
  FileField,
  FileInput,
  Labeled,
  LoadingIndicator,
  maxLength,
  required,
  TextInput,
  useDataProvider,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { CustomDataProvider } from "src/auth";

import { ActivityItem, AudioContent } from "../types";

interface PractitionerInputAudioProps {
  scopedFormData: ActivityItem;
  getSource: (string) => string;
}

export const PractitionerInputAudio: FC<PractitionerInputAudioProps> = ({
  scopedFormData,
  getSource,
}) => {
  const { uploadAudio, getSignedUrl } = useDataProvider<CustomDataProvider>();
  const { setValue, setError, clearErrors } = useFormContext();
  const { mutateAsync, isLoading } = useMutation({
    mutationKey: ["audio-upload", scopedFormData.id],
    mutationFn: async function (
      file: File
    ): Promise<Omit<AudioContent, "transcription">> {
      clearErrors(getSource("audio"));
      return await uploadAudio(file);
    },
    onSuccess(data) {
      setValue(
        getSource("audio"),
        {
          ...data,
          transcription: scopedFormData.audio?.transcription,
        },
        {
          shouldTouch: true,
        }
      );
    },
    onError(error: Error) {
      setError(
        getSource("audio"),
        {
          message: error.message,
          type: "custom",
        },
        { shouldFocus: true }
      );
      setValue(
        getSource("audio"),
        {
          transcription: scopedFormData.audio?.transcription,
        },
        {
          shouldTouch: true,
        }
      );
    },
  });

  const duration = useWatch<Record<string, number>>({
    name: getSource("audio.duration"),
  });

  const size = useWatch<Record<string, number>>({
    name: getSource("audio.size"),
  });

  const { data: signedUrl } = useQuery({
    queryKey: ["signed-url", scopedFormData.audio?.src],
    queryFn: async function ({ queryKey }) {
      const key = queryKey[1];

      if (!key || key.includes("blob")) {
        return undefined;
      }

      return await getSignedUrl(key);
    },
  });

  return (
    <Box width={"100%"}>
      <FileInput
        validate={[required()]}
        source={getSource("audio")}
        label="Activity audio"
        multiple={false}
        accept={"audio/mpeg"}
        maxSize={6 * Math.pow(10, 7) /* 60MB */}
        helperText="Upload mp3 file, not larger than 60MB, max bitrate 128 kbps"
        options={{
          onDrop([file]) {
            if (!file) {
              setError(
                getSource("audio"),
                {
                  message: "File is not a mp3 or is too large",
                  type: "required",
                },
                { shouldFocus: true }
              );
              return;
            }

            void mutateAsync(file);
          },
          disabled: isLoading,
        }}
        onRemove={() => {
          setValue(getSource("audio"), {
            transcription: scopedFormData.audio.transcription,
          });
          setError(getSource("audio"), { message: "Audio file is required" });
        }}
      >
        <FileField source="src" title={scopedFormData.displayValue} />
      </FileInput>
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <Box sx={{ flexDirection: "row", display: "flex" }}>
          <Labeled label="Duration (seconds)" sx={{ flex: 1 }}>
            <Typography variant="body2" component="span">
              {duration}
            </Typography>
          </Labeled>
          <Labeled label="Size (MB)" sx={{ flex: 1 }}>
            <Typography variant="body2" component="span">
              {size}
            </Typography>
          </Labeled>
        </Box>
      )}
      {signedUrl ? (
        <Box>
          <audio controls preload="metadata">
            <source src={signedUrl} />
          </audio>
        </Box>
      ) : null}
      <TextInput
        source={getSource("audio.transcription")}
        label="Transcription"
        helperText="Max length 3000 characters"
        validate={[maxLength(3000)]}
        rows={10}
        multiline
        sx={{ width: 520 }}
      />
    </Box>
  );
};
