import { Box } from "@mui/material";
import { FC } from "react";

import { DeletionRequestInfoTable } from "../components/DeletionRequestInfoTable";
import { InfoCard } from "../components/InfoCard";
import { InfoTable } from "../components/InfoTable";
import { OccurrenceInfoTable } from "../components/OccurrenceInfoTable";
import { TopActivitiesInfoTable } from "../components/TopActivitiesInfoTable";


const Statistics: FC = () => {
    return (
        <>
          <Box sx={{ marginTop: 1.5, gap: 1 }} flexWrap="wrap" display="flex">
            <InfoCard resource="institutions" label="Institutions" />
            <InfoCard
              resource="statistics/all-practitioners"
              label="Practitioners"
            />
            <InfoCard resource="statistics/all-patients" label="Patients" />
            <InfoCard
              resource="statistics/assigned-occurrences"
              label="Assigned activity occurrences"
            />
            <InfoCard
              resource="statistics/completed-occurrences"
              label="Completed activity occurrences"
            />
          </Box>
          <Box sx={{ marginTop: 1.5, gap: 1 }} flexWrap="wrap" display="flex">
            <InfoTable
              columns={["Active patients"]}
              headerLabel="Active patients"
              resource="statistics/active-patients"
              subtitle="Logged-in in given period"
            />
            <InfoTable
              headerLabel="Patient invitations"
              columns={["Invitations sent"]}
              resource="statistics/patient-invitations"
            />
            <InfoTable
              columns={["New patients"]}
              headerLabel="New patients"
              resource="statistics/new-patients"
            />
            <OccurrenceInfoTable />
            <DeletionRequestInfoTable />
            <InfoTable
              columns={["Active practitioners"]}
              headerLabel="Active practitioners"
              resource="statistics/active-practitioners"
              subtitle="Logged-in in given period"
            />
            <InfoTable
              columns={["Filled in therapy goals"]}
              headerLabel="Filled in therapy goals"
              resource="statistics/therapy-goals"
              valueType="percent"
            />
            <TopActivitiesInfoTable />
          </Box>
        </>
    )
}

export default Statistics;