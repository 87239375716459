import { ActivityForCreate, ActivityItemType } from "./types";

export const transformDataBeforeRequest = async (data: ActivityForCreate) => {
  data.items = await Promise.all(
    data.items.map(async (item, itemIndex) => {
      const modifiedItem = {
        ...item,
        answerOptions: item.answerOptions ? item.answerOptions.map(
          (answerOption, answerOptionIndex) => ({
            ...answerOption,
            value: answerOption.value === "" ? undefined : answerOption.value,
            order: answerOptionIndex + 1
          })
        ) : undefined,
        picture: item.picture
          ? {
            src: await toBase64(item.picture),
            imageDescription: item.imageDescription,
            alt: item.alt
          }
          : undefined,
        order: itemIndex + 1,
        displayValue: item.displayValue || "none",
        version: item.version || 1,
        recordType: <string>item.recordType === '' ? undefined : item.recordType
      };

      if (item.activityItemType === ActivityItemType.PATIENT_RESPONSE_SMILEY_SCALE) {
        modifiedItem.answerOptions = [
          { name: '5', value: '5', order: 1 },
          { name: '4', value: '4', order: 2 },
          { name: '3', value: '3', order: 3 },
          { name: '2', value: '2', order: 4 },
          { name: '1', value: '1', order: 5 },
          { name: '0', value: '0', order: 6 }
        ];
      }

      if (item.activityItemType !== ActivityItemType.PRACTITIONER_INPUT_AUDIO) {
        modifiedItem.audio = undefined
      }

      return modifiedItem;
    })
  );
  data.references = data.references ?? [];

  //remove unused props
  data.id = undefined;
  if (
    !data.isEvaluationEnabled ||
    !data.scores.some((score) => score.isMainScore)
  ) {
    data.evaluation = undefined;
  }
  data.isEvaluationEnabled = undefined;

  return data;
};

export const toBase64 = (file: {
  src: string;
  rawFile?: File;
  title?: string;
}): Promise<string> =>
  new Promise((resolve, reject) => {
    if (!file.rawFile) {
      resolve(file.src);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file.rawFile);
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    }
  });
