import { useNotify } from "react-admin";

interface CustomError extends Omit<Error, "message"> {
  message?: Array<string> | string;
}

export function useMutationErrorNotify() {
  const notify = useNotify();

  return (e: CustomError) => {
    const message = Array.isArray(e.message) ? e.message.join(", ") : e.message;

    notify(message, {
      type: "error",
    });
  };
}
