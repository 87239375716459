import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Datagrid, DateField, List, ReferenceField, useDataProvider } from 'react-admin';

import { consentsContainer } from './commonStyling';

interface Consent {
  id: string;
  policyId: string;
  consentAt: string;
  revokedAt?: string;
}

interface CustomSortedListProps {
  userId: string;
}

const CustomSortedList: React.FC<CustomSortedListProps> = ({ userId }) => {
  const [data, setData] = useState<Consent[]>([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await dataProvider.getList<Consent>(`users/${userId}/consents`, {
          sort: { field: 'revokedAt', order: 'DESC' },
          filter: {},
          pagination: { page: 1, perPage: 0 },
        });

        const sortedData = data.sort((a, b) => {
          const revokedAtA = a.revokedAt ? new Date(a.revokedAt).getTime() : 0;
          const revokedAtB = b.revokedAt ? new Date(b.revokedAt).getTime() : 0;

          if (revokedAtA !== revokedAtB) {
            return revokedAtB - revokedAtA;
          }

          const consentAtA = a.consentAt ? new Date(a.consentAt).getTime() : 0;
          const consentAtB = b.consentAt ? new Date(b.consentAt).getTime() : 0;

          return consentAtB - consentAtA;
        });

        setData(sortedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    void fetchData();
  }, [dataProvider, userId]);

  return (
    <Box sx={consentsContainer}>
      <Typography>Consents</Typography>
      <List
        title=" "
        pagination={false}
        actions={false}
        resource={`users/${userId}/consents`}
      >
        <Datagrid data={data} bulkActionButtons={false}>
          <ReferenceField
            label="Consent"
            source="policyId"
            reference="policies"
            emptyText="-"
            sortable={false}
          />
          <DateField source="consentAt" emptyText="-" sortable={false} />
          <DateField source="revokedAt" emptyText="-" sortable={false} />
          <DateField source="rejectedAt" emptyText="-" sortable={false} />
        </Datagrid>
      </List>
    </Box>
  );
};

export default CustomSortedList;