import "./style.css";

import AddIcon from "@mui/icons-material/AddCircleOutline";
import ArrowDownwardIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowUpwardIcon from "@mui/icons-material/ArrowCircleUp";
import { IconButton, Tooltip } from "@mui/material";
import { FC, useRef } from "react";
import { useSimpleFormIterator, useSimpleFormIteratorItem } from "react-admin";

export const FormItemButtons: FC = () => {
  const { index, total, reOrder } = useSimpleFormIteratorItem();
  const { add, reOrder: formReOrder } = useSimpleFormIterator();
  const addButtonRef = useRef<HTMLButtonElement>();

  return (
    <>
      <Tooltip title="Add one below">
        <span>
          <IconButton
            size="small"
            onClick={() => {
              add();
              formReOrder(total, index + 1);
              setTimeout(() => {
                addButtonRef.current?.scrollIntoView();
                const addedListItem =
                  document.getElementsByClassName("items-list")[0]?.children[0]
                    ?.children[index + 1];

                addedListItem?.classList.add("blink");

                setTimeout(() => {
                  addedListItem?.classList.remove("blink");
                }, 3000);
              }, 500);
            }}
            disabled={total == null || index >= total - 1}
            color="primary"
            ref={addButtonRef}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Move up">
        <span>
          <IconButton
            size="small"
            onClick={() => reOrder(index - 1)}
            disabled={index <= 0}
          >
            <ArrowUpwardIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Move down">
        <span>
          <IconButton
            size="small"
            onClick={() => reOrder(index + 1)}
            disabled={total == null || index >= total - 1}
          >
            <ArrowDownwardIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};
