export const PatientRecordChoices = [
    { id: "GOAL", name: "Goal" },
    { id: "RESOURCE", name: "Resource" },
    { id: "MENTAL_CHALLENGE", name: "Mental Challenge" },
    { id: "PREVENTION", name: "Prevention" },
    { id: "VULNERABILITY", name: "Vulnerability" },
    { id: "WARNING_SIGN", name: "Warning Sign" },
    { id: "PERCEPTION", name: "Perception of others" },
    { id: "SKILL", name: "Skill" },
    { id: "EMERGENCY_DETECTION", name: "Detection of an emergency" },
]