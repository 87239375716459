import { FC } from "react";
import { useContext } from "react";
import { ChipField, Datagrid, DateField, FunctionField, List, ListProps, ReferenceArrayField, SingleFieldList, TextField } from "react-admin";
import { NoValue } from "src/components/NoValue";
import { UserInfo } from "src/components/UserInfo";
import { AppRoleContext } from "src/context/AppRoleContext/AppRoleContext";

import { RemoveUserButton } from "./removeUserButton";
import { RemoveRequest } from "./types";

const RequestsList: FC<ListProps<RemoveRequest>> = () => {
  const { role } = useContext(AppRoleContext);
  
  return (  
    <List title="Remove Account Requests">
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          label="User"
          render={(record) => {
              if (!record) {
                return <NoValue />;
              }
              
              return role === "SUPERUSER" ? (
                <UserInfo source="userId" />
              ) : (
                  <NoValue />
              );
          }}
        />
        <DateField source="createdAt" label="Requested Date"/>
        <TextField source="requestType" label="Account Type" sortable={false} />
        <ReferenceArrayField
            label="Institutions"
            reference="institutions"
            source="institutionsIds"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        <RemoveUserButton />
      </Datagrid>
    </List>
  )
};

export default RequestsList;
