import { toBase64 } from "src/activities/transformDataBeforeRequest";
import { ActivityItemType } from "src/activities/types";

import { Journal } from "./types";


export const transformDataBeforeRequest = async (data: Journal) => {
  data.pages = data.pages.map(page => ({
    id: page.id,
    timePeriod: page.timePeriod,
    feature: undefined,
    items: undefined,
    status: undefined,
    version: undefined,
    title: undefined
  }));

  if (!data.addIntroduction) {
    data.introduction = undefined;
  } else {
    data.introduction.items = await Promise.all(
      data.introduction.items.map(async (item, itemIndex) => {
        const modifiedItem = {
          ...item,
          answerOptions: item.answerOptions ? item.answerOptions.map(
            (answerOption, answerOptionIndex) => ({
              ...answerOption,
              value: answerOption.value === "" ? undefined : answerOption.value,
              order: answerOptionIndex + 1
            })
          ): undefined,
          picture: item.picture
          ? {
              src: await toBase64(item.picture),
              imageDescription: item.imageDescription,
              alt: item.alt
            }
          : undefined,
          order: itemIndex + 1,
          displayValue: item.displayValue || "none",
          version: item.version || 1,
          recordType: <string>item.recordType === '' ? undefined : item.recordType
        }

        if (item.activityItemType === ActivityItemType.PATIENT_RESPONSE_SMILEY_SCALE) {
          modifiedItem.answerOptions = [
            { name: '5', value: '5', order: 1 },
            { name: '4', value: '4', order: 2 },
            { name: '3', value: '3', order: 3 },
            { name: '2', value: '2', order: 4 },
            { name: '1', value: '1', order: 5 },
            { name: '0', value: '0', order: 6 }
          ];
        }

        return modifiedItem;
      }
    ))
  }

  data.addIntroduction = undefined;

  return data;
};

