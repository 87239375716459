import { FC } from "react";
import {
  Button as ReactAdminButton,
  useDataProvider,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

import { CustomDataProvider } from "../../../auth";
import { Practitioner } from "../../types";

export const AssignToTeamButton: FC = () => {
  const { institutionId, teamId } = useParams();
  const { id } = useRecordContext<Practitioner>();
  const dataProvider = useDataProvider<CustomDataProvider>();
  const refresh = useRefresh();

  const { mutateAsync } = useMutation(() =>
    dataProvider.assignPractitionerToTeam(institutionId, id, teamId)
  );

  const handleClick = async () => {
    await mutateAsync();
    refresh();
  };

  return (
    <ReactAdminButton
      label="Assign"
      onClick={(e) => {
        e.stopPropagation();
        void handleClick();
      }}
    />
  );
};
