import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { FC, useState } from "react";
import {
  Button as ReactAdminButton,
  useDataProvider,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";

import { CustomDataProvider } from "../../../auth";
import { Institution, Patient } from "../../types";

interface RemoveFromInstitutionButtonProps {
  onRemoveSuccess?: () => void;
}

export const RemoveFromInstitutionButton: FC<RemoveFromInstitutionButtonProps> = ({ onRemoveSuccess }) => {
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const institutionId = pathname.split("/")[2] || "";
  const { id, firstName, lastName } = useRecordContext<Patient>();
  const dataProvider = useDataProvider<CustomDataProvider>();
  const refresh = useRefresh();

  const institution = useGetOne<Institution>(
    `institutions`, { id: institutionId }
  ).data;

  const { mutateAsync } = useMutation(() =>
    dataProvider.removePatientFromInstitution(institutionId, id)
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    await mutateAsync();
    refresh();

    if (onRemoveSuccess) {
      onRemoveSuccess();
    }

    notify('Successfully removed from institution', { type: 'success' });
  };

  return (
    <>
      <ReactAdminButton
        label="Remove"
        onClick={(e) => {
        e.stopPropagation();
        void handleClick();
        }}
      />
      <Dialog
        open={open}
        onClose={handleDialogClose}
        onClick={(e) => e.stopPropagation()}
        >
          <DialogTitle>
            {`Are you sure you want to unassign ${firstName} ${lastName} from ${institution?.name}?`}
          </DialogTitle>
          <DialogActions>
            <ReactAdminButton
              label="Cancel"
              onClick={(e) => {
                e.stopPropagation();
                handleDialogClose();
              }}
            />
            <ReactAdminButton
              label="Confirm"
              onClick={(e) => {
                e.stopPropagation();
                void handleConfirm();
              }}
            />
          </DialogActions>
      </Dialog>
    </>
  );
};
