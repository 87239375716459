import { FC } from "react";
import {
  ArrayInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import {
  commonAnswerOptionsListStyling,
  commonStringArrayOptionsStyling,
} from "src/activities/commonStyling";
import { AccessibilityRestrictionChoices } from "src/activities/selectChoices/accessibilityRestrictionChoices";
import { activityModuleChoices } from "src/activities/selectChoices/activityModuleChoices";
import { IndicationChoices } from "src/activities/selectChoices/indicationChoices";
import { RecommendedFrequencyChoices } from "src/activities/selectChoices/recommendedFrequencyChoices";
import { GroupedSelectInput } from "src/components/GroupSelectInput";

export const JournalProfessionalInformation: FC = () => (
  <>
    <TextInput source="activityGoal" validate={required()} fullWidth multiline />
    <TextInput source="instruction" fullWidth multiline />
    <ArrayInput source="modules" sx={commonAnswerOptionsListStyling}>
      <SimpleFormIterator sx={commonStringArrayOptionsStyling}>
        <GroupedSelectInput
          source=""
          label="Module Name"
          choices={activityModuleChoices}
          validate={[required()]}
        />
      </SimpleFormIterator>
    </ArrayInput>
    <ArrayInput source="indication" sx={commonAnswerOptionsListStyling}>
      <SimpleFormIterator sx={commonStringArrayOptionsStyling}>
        <SelectInput
          source=""
          validate={required()}
          fullWidth
          label="Indication Name"
          choices={IndicationChoices}
        />
      </SimpleFormIterator>
    </ArrayInput>
    <TextInput source="contraIndication" fullWidth multiline />
    <SelectInput
      source="recommendedFrequency"
      choices={RecommendedFrequencyChoices.filter(
        (choice) => choice.id !== "ONE_TIME"
      )}
      fullWidth
    />
    <SelectInput
      source="accessibilityRestriction"
      choices={AccessibilityRestrictionChoices}
      fullWidth
    />
    <ArrayInput source="references" sx={commonAnswerOptionsListStyling}>
      <SimpleFormIterator sx={commonStringArrayOptionsStyling}>
        <TextInput source="label" validate={required()} fullWidth />
        <TextInput source="link" type="url" fullWidth />
      </SimpleFormIterator>
    </ArrayInput>
    <TextInput
      source="feedback"
      type="url"
      fullWidth
      helperText="You can provide a link to an external service, where therapists can leave feedback about this activity"
    />
  </>
);
