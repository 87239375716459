import {
  Box,
  Typography} from "@mui/material";
import { FC, useContext, useState } from "react";
import {
  Datagrid,
  DateField,
  List,
  Pagination,
  TextField,
  Title,
  useFilterState,
  useGetList,
  usePaginationState,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { Statistics } from "src/common/statistics";
import SearchBar from "src/components/SearchBar";
import { AppRoleContext } from "src/context/AppRoleContext/AppRoleContext";

import InstitutionEdit from "..";
import { commonDatagridStyling } from "../commonStyling";
import { AssignToInstitutionButton } from "./assignToInstitutionButton";
import { RemoveFromInstitutionButton } from "./removeFromInstitutionButton";

const InstitutionPatientsList: FC = () => {
  const { role } = useContext(AppRoleContext);
  const { pathname } = useLocation();
  const institutionId = pathname.split("/")[2] || "";
  const paginationForPatientsInInstitution = usePaginationState({ perPage: 5 });
  const paginationForAllPatients = usePaginationState({ perPage: 5 });
  const { filter, setFilter } = useFilterState({});
  const [searchText, setSearchText] = useState("");
  
  const allPatients = useGetList("users/patients", { pagination: paginationForAllPatients, filter });
  const patientsInInstitution = useGetList(
    `institutions/${institutionId}/patients`,
    { pagination: paginationForPatientsInInstitution }
  );


  const handleFilter = () => {
    setFilter(searchText);
  };

  const handleClearFilters = () => {
    setFilter('');
    setSearchText("");
  };

  const handleRemoveSuccess = () => {
    if (patientsInInstitution.data.length === 1 && paginationForPatientsInInstitution.page > 1) {
      paginationForPatientsInInstitution.setPage(paginationForPatientsInInstitution.page - 1);
    } else {
      void patientsInInstitution.refetch();
    }
  };

  return (
    <InstitutionEdit>
      <Box display="flex" flexDirection="column" gap="50px">
        <Title title="Patients" />
        <List
          pagination={false}
          aside={
            <Statistics resource={`institutions/${institutionId}/patients`} />
          }
          title={" "}
          actions={false}
          exporter={false}
        >
          {role === "SUPERUSER" ? (
            <Box>
              <Datagrid
                rowClick={(id) => `${id}`}
                bulkActionButtons={false}
                {...patientsInInstitution}
              >
                <TextField source="firstName" sortable={false} />
                <TextField source="lastName" sortable={false} />
                <TextField source="email" sortable={false} />
                <DateField source="lastLogin" emptyText="-" fullWidth showTime sortable={false} />
                <RemoveFromInstitutionButton onRemoveSuccess={handleRemoveSuccess} />
                
              </Datagrid>
              <Pagination
                {...paginationForPatientsInInstitution} total={patientsInInstitution.total}
              />
            </Box>
          ) : null}
        </List>
        {role === "SUPERUSER" ? (
          <Box>
            <Typography
              sx={{
                padding: 2,
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              All patients
            </Typography>
            <SearchBar
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={handleFilter}
              onClear={handleClearFilters}
            />
            <Datagrid
              bulkActionButtons={false}
              sx={commonDatagridStyling}
              sort={{ field: "id", order: "DESC" }}
              {...allPatients}
            >
              <TextField source="firstName" />
              <TextField source="lastName" />
              <TextField source="email" />
              <AssignToInstitutionButton
                institutionId={institutionId}
                resource={`institutions/${institutionId}/patients`}
              />
            </Datagrid>
            <Pagination {...paginationForAllPatients} total={allPatients.total} />
          </Box>
        ) : null}
      </Box>
    </InstitutionEdit>
  );
};

export default InstitutionPatientsList;
