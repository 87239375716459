import { Box } from "@mui/material";
import { FC } from "react";
import { Show, ShowActions, TabbedForm } from "react-admin";
import { commonToolbarStyling } from "src/activities/commonStyling";

import InstitutionStatistics from "./components/InstitutionStatistics";
import PractitionersStatisticList from "./components/PractitionersStatisticList";

const InstitutionShowToolbar = () => <Box sx={commonToolbarStyling}></Box>;

const InstitutionShow: FC = () => {
  return (
    <Show title="Institutions" actions={<ShowActions />}>
      <TabbedForm toolbar={<InstitutionShowToolbar />}>
        <TabbedForm.Tab label="Institution Statistics" >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            paddingBottom="100px"
            gap="3%"
          >
            <InstitutionStatistics />
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Practitioners">
          <PractitionersStatisticList />
        </TabbedForm.Tab>
      </TabbedForm>
    </Show>
  );
};

export default InstitutionShow;
