import { CheckCircle, Error, Warning } from '@mui/icons-material';
import { Box, CircularProgress, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';

interface SystemStatusProps {
  systemName: string;
  isOk: boolean | null;
  partialOutage?: boolean;
}

const SystemStatus: FC<SystemStatusProps> = ({ systemName, isOk, partialOutage }) => {
    return (
      <TableRow>
        <TableCell>
          <Box sx={{ width: '100%' }}>{systemName}</Box>
        </TableCell>
        <TableCell>
          {isOk !== null ? (
            <>
              {!isOk ? (
                <>
                  {partialOutage ? (
                    <>
                      <Warning sx={{ color: 'orange', verticalAlign: 'middle' }} />
                      <span style={{ paddingLeft: '5px', color: 'orange' }}>Partial outage</span>
                    </>
                  ) : (
                    <>
                      <Error sx={{ color: 'red', verticalAlign: 'middle' }} />
                      <span style={{ paddingLeft: '5px', color: 'red' }}>Major outage</span>
                    </>
                  )}
                </>
              ) : (
                <>
                  <CheckCircle sx={{ color: 'green', verticalAlign: 'middle' }} />
                  <span style={{ paddingLeft: '5px', color: 'green' }}>Operational</span>
                </>
              )}
            </>
          ) : (
            <CircularProgress size={20} sx={{ color: 'blue' }} />
          )}
        </TableCell>
      </TableRow>
    );
  };
  
  export default SystemStatus;