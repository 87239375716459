import { Box, Card, CardContent, Typography } from "@mui/material";
import { FC } from "react";
import { LoadingIndicator } from "react-admin";

import DashboardDataTable from "../dashboardDataTable";

interface TableComponentProps {
  headerLabel: string;
  columns: Array<string>;
  disableChangeForColumns?: Array<number>;
  subtitle?: string;
  isLoading: boolean;
  lastMonth: string;
  tableConfig: Array<{
    month: string;
    values: Array<{ value: string; change: number }>;
  }>;
}

export const TableComponent: FC<TableComponentProps> = ({
  isLoading,
  columns,
  subtitle,
  headerLabel,
  lastMonth,
  tableConfig,
  disableChangeForColumns,
}) => {
  if (isLoading) {
    return (
      <Card sx={{ minWidth: 560 }} raised>
        <CardContent sx={{ display: "flex", justifyContent: "center" }}>
          <LoadingIndicator />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ width: 560 }} raised>
      <CardContent sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h5"
              sx={{ color: "#4F4F4F", textAlign: "left" }}
            >
              {headerLabel}
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "#4F4F4F" }}>
              {subtitle ?? ""}
            </Typography>
          </Box>
          <Box sx={{ textAlign: "right" }}>
            <Typography variant="h6">{lastMonth ?? "-"}</Typography>
            <Typography variant="subtitle1" sx={{ color: "#4F4F4F" }}>
              Last 30 days
            </Typography>
          </Box>
        </Box>
        <DashboardDataTable
          data={tableConfig}
          columns={["Month", ...columns]}
          disableChangeForColumns={disableChangeForColumns}
        />
      </CardContent>
    </Card>
  );
};
