import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import {
  BooleanInput,
  Create,
  minLength,
  PasswordInput,
  required,
  SelectArrayInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useLocation } from 'react-router-dom';

import { useMutationErrorNotify } from "../common/handleMutationError";
import { RoleChoices } from "../institutions/InstitutionEdit/practitioners/roleChoices";
import { RequiredActionsChoices } from "./requiredActionsChoices";
import { Practitioner } from "./types";

const PractitionersCreate = () => {
  const location = useLocation();
  const [roles, setRoles] = useState<Array<string>>([]);
  const queryParams = new URLSearchParams(location.search);

  const institutionId = queryParams.get('institutionId');

  const notify = useMutationErrorNotify();

  const transformCreateDataBeforeRequest = (data: Practitioner) => {
    data.roles = roles;
    data.institutionId = institutionId;

    return data;
  };

  return (
    <Create
      title="Practitioners"
      transform={transformCreateDataBeforeRequest}
      mutationOptions={{
        onError: notify,
      }}
    >
      <SimpleForm>
        <Box
          display="flex"
          flexDirection="column"
          width={{ xs: "100%", xl: 1414 }}
          gap="3%"
        >
          <TextInput
            source="email"
            type="email"
            validate={required()}
            fullWidth
          />
          <PasswordInput
            source="password"
            validate={[required(), minLength(8)]}
            fullWidth
          />
          <TextInput source="firstName" validate={required()} fullWidth />
          <TextInput source="lastName" validate={required()} fullWidth />
          <SelectArrayInput
            source="requiredActions"
            choices={RequiredActionsChoices}
          />
          {institutionId && 
            <Box>
              <InputLabel id="role-select-label">Role</InputLabel>
              <Select
                label="Role"
                required={true}
                labelId="role-select-label"
                value={roles}
                multiple={true}
                style={{ width: '200px' }}
                onChange={(e) => setRoles(e.target.value as Array<string>)}
              >
                {RoleChoices.map((choice, index) => (
                  <MenuItem key={index} value={choice.id}>
                    {choice.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          }
          <BooleanInput
            source="isPasswordTemporary"
            defaultValue={true}
            fullWidth
          />
          <BooleanInput
            source="isTesting"
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default PractitionersCreate;
