import { Box } from "@mui/material";
import { Edit, SaveButton, TabbedForm, Toolbar } from "react-admin";

import { useMutationErrorNotify } from "../common/handleMutationError";
import { JournalPageOverview } from "./subforms/journalPageOverview";
import { transformDataBeforeRequest } from "./transformDataBeforeRequest";

const JournalPageEditToolbar = (props) => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const JournalPageEdit = () => {
  const notify = useMutationErrorNotify();

  return (
    <Edit
      title="Journal Page"
      transform={transformDataBeforeRequest}
      mutationMode="pessimistic"
      mutationOptions={{
        onError: notify
      }}
    >
      <TabbedForm toolbar={<JournalPageEditToolbar />}>
        <TabbedForm.Tab label="Overview">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <JournalPageOverview />
          </Box>
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};

export default JournalPageEdit;
