import { Box } from "@mui/material";
import { Create, required, SimpleForm, TextInput } from "react-admin";
import { useParams } from "react-router-dom";

const TeamCreate = () => {
  const { institutionId } = useParams();

  return (
    <Create title="Team" resource={`institutions/${institutionId}/teams`}>
      <SimpleForm>
        <Box
          display="flex"
          flexDirection="column"
          width={{ xs: "100%", xl: 1414 }}
          gap="3%"
        >
          <TextInput source="name" validate={required()} fullWidth />
          <TextInput source="description" multiline fullWidth />
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default TeamCreate;
