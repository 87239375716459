import { SxProps } from "@mui/material";

export const searchBarContainer: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
};

export const searchButton: SxProps = {
  ml: 1,
}