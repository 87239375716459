import { Datagrid, List, TextField } from "react-admin";
import { StatisticsByStatus } from "src/common/statisticsByStatus";

const PathwayList = () => (
  <List aside={<StatisticsByStatus statusName="publicationStatus" />}>
    <Datagrid
      rowClick="edit"
      bulkActionButtons={false}
      sx={{
        "& .column-title": { width: "50%" }
      }}
    >
      <TextField source="title" />
      <TextField source="status" sortBy="publicationStatus"/>
      <TextField source="activityCount" sortable={false} />
      <TextField source="timeSpan" label="Time span (days)" sortable={false} />
      <TextField source="duration" label="Duration (minutes)" sortable={false} />
    </Datagrid>
  </List>
);

export default PathwayList;
