import { Patient } from "./types";

export const transformEditDataBeforeRequest = (data: Patient) => {
  const dataToTransform = data;

  dataToTransform.id = undefined;
  dataToTransform.email = undefined;
  dataToTransform.lastLogin = undefined;

  return dataToTransform;
};

export const transformCreateDataBeforeRequest = (data: Patient) => {
  const dataToTransform = data;

  dataToTransform.birthdate = new Date(data.birthdate).toISOString();

  return dataToTransform;
};
