import { ReferenceField, TextField, useRecordContext } from 'react-admin';

import { NoValue } from '../NoValue';

interface UserInfoProps {
  source: string;
}

export const UserInfo: React.FC<UserInfoProps> = ({ source }) => {
  const record = useRecordContext();

  if (!record) {
    return <NoValue />;
  }
  
  return (
    <div>
      <ReferenceField source={source} reference="users/patients">
        <TextField source="firstName" /> <TextField source="lastName" />
      </ReferenceField>
      
      <ReferenceField source={source} reference="users/practitioners">
        <TextField source="firstName" /> <TextField source="lastName" />
      </ReferenceField>
    </div>
  );
};