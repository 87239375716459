import Difference from "@mui/icons-material/Difference";

import CreateUDI from "./create";
import EditUDI from "./edit";
import ListUDI from "./list";
import { UDI } from "./types";

export default {
  edit: EditUDI,
  create: CreateUDI,
  icon: Difference,
  list: ListUDI,
  recordRepresentation: (record: UDI) => `${record.udiPi} - ${record.status}`,
  options: { label: 'Unique Device Identifiers' }
};
