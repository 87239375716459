import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC } from "react";

type Data = {
  month: string;
  values: Array<{ value: string; change: number }>;
};

type DashboardDataTableProps = {
  data: Data[];
  columns: Array<string>;
  disableChangeForColumns?: Array<number>;
};

const DashboardDataTable: FC<DashboardDataTableProps> = ({
  data,
  columns,
  disableChangeForColumns = [],
}) => {
  function formatChange(value: number) {
    if (value === 0) {
      return <span>{"(0%)"}</span>;
    }

    if (value < 0) {
      return (
        <span style={{ color: "red" }}>{`(${(value * 100).toFixed(2)}%)`}</span>
      );
    }

    return (
      <span style={{ color: "green" }}>{`(+${(value * 100).toFixed(
        2
      )}%)`}</span>
    );
  }

  return (
    <TableContainer component={Paper} style={{ marginTop: "20px" }}>
      <Table sx={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow style={{ backgroundColor: "#B9B9B9" }}>
            {columns.map((column, index) => (
              <TableCell key={index}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ month, values }, rowIndex) => (
            <TableRow key={`${rowIndex}`}>
              <TableCell>{month}</TableCell>
              {values.map(({ value, change }, columnIndex) => (
                <TableCell key={`${rowIndex}_${columnIndex}`}>
                  {`${value} `}
                  {disableChangeForColumns.some(
                    (column) => column === columnIndex + 1
                  ) ? (
                    <span />
                  ) : (
                    formatChange(change)
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardDataTable;
