import { FC } from "react";
import {
  ArrayInput,
  FormDataConsumer,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput
} from "react-admin";
import { useWatch } from "react-hook-form";
import { FormItemButtons } from "src/components/FormItemButtons";

import { commonItemsStyling } from "../../commonStyling";
import { ScoreChoices } from "../../selectChoices/scoreChoices";
import {
  Activity,
  ActivityItem,
  ActivityScore,
  EvaluationConfig
} from "../../types";
import { CalculationItems } from "./calculationItems";
import { ScoreInterpretations } from "./scoreInterpretations";
import { ScoreTypeConfig } from "./scoreTypeConfig";

const ActivityScoring: FC = () => {
  const scores = useWatch<Activity>({ name: "scores" }) as
    | Array<ActivityScore>
    | undefined;

  const items = useWatch<Activity>({ name: "items" }) as
    | Array<ActivityItem>
    | undefined;

  const evaluation = useWatch<Activity>({ name: "evaluation" }) as
    | EvaluationConfig
    | undefined;

  const isEvaluationEnabled = useWatch<Activity>({
    name: "isEvaluationEnabled"
  }) as boolean | undefined;

  return (
    <>
      <ArrayInput source="scores" required>
        <SimpleFormIterator
          sx={commonItemsStyling}
          inline
          getItemLabel={(index) => `#${index + 1}`}
          reOrderButtons={<FormItemButtons />}
          className="items-list"
        >
          <TextInput source="name" validate={required()} fullWidth />
          <TextInput
            source="semanticIdentifier"
            validate={required()}
            fullWidth
          />
          <SelectInput
            source="calculationType"
            validate={required()}
            fullWidth
            choices={ScoreChoices}
          />
          <FormDataConsumer<Activity>>
            {({ getSource }: { getSource?: (string) => string }) => {
              return <ScoreTypeConfig getSource={getSource} scores={scores} />;
            }}
          </FormDataConsumer>
          <FormDataConsumer<Activity>>
            {({
              getSource
            }: {
              getSource?: (string) => string;
              formData: Activity;
            }) => {
              return (
                <CalculationItems
                  getSource={getSource}
                  scores={scores}
                  items={items}
                  hasEvaluation={isEvaluationEnabled && !!evaluation}
                />
              );
            }}
          </FormDataConsumer>
          <FormDataConsumer<Activity>>
            {({ getSource }: { getSource?: (string) => string }) => {
              return (
                <ScoreInterpretations
                  getSource={getSource}
                  scores={scores}
                  hasEvaluation={isEvaluationEnabled && !!evaluation}
                />
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

export default ActivityScoring;
