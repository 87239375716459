import { Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
import { FC, useMemo, useState } from "react";
import {
  Button as ReactAdminButton,
  useDataProvider,
  useGetList,
  useGetOne,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useMutation } from "react-query";

import { CustomDataProvider } from "../../../auth";
import { Institution, Patient, Practitioner } from "../../types";

export const AssignToInstitutionButton: FC<{
  institutionId: string;
  resource: string;
}> = ({ institutionId, resource }) => {
  const [open, setOpen] = useState(false);
  const { id, firstName, lastName } = useRecordContext<Practitioner>();
  const { data, isLoading } = useGetList<Patient>(resource);
  const dataProvider = useDataProvider<CustomDataProvider>();
  const refresh = useRefresh();

  const institution = useGetOne<Institution>(
    `institutions`, { id: institutionId }
  ).data;

  const { mutateAsync, isLoading: isMutating } = useMutation(() =>
    dataProvider.assignPatientToInstitution(institutionId, id)
  );

  const isAlreadyAssigned = useMemo(() => {
    return data?.some((patient) => patient.id === id);
  }, [data, id]);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    await mutateAsync();
    refresh();
    setOpen(false);
  };

  return (
    <>
      {isAlreadyAssigned ? (
        <Typography variant="body2">Patient already assigned</Typography>
      ) : (
        <ReactAdminButton
          label="Assign"
          disabled={isLoading || isMutating}
          onClick={(e) => {
            e.stopPropagation();
            void handleClick();
          }}
        />
      )}
      <Dialog
        open={open}
        onClose={handleDialogClose}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>
          {`Are you sure you want to assign ${firstName} ${lastName} to ${institution?.name}?`}
        </DialogTitle>
        <DialogActions>
          <ReactAdminButton
            label="Cancel"
            onClick={(e) => {
              e.stopPropagation();
              handleDialogClose();
            }}
          />
          <ReactAdminButton
            label="Confirm"
            onClick={(e) => {
              e.stopPropagation();
              void handleConfirm();
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
