import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { PublicationStatus } from "src/activities/types";
import StatisticItem from "src/components/StatisticItem";

import { containerStyles } from "./commonStyling";

export interface StatisticsProps {
  resource?: string;
  onlyTotal?: boolean;
  statusName?: 'status' | 'publicationStatus'
}

export const StatisticsByStatus: FC<StatisticsProps> = ({ resource, onlyTotal, statusName = 'status' }) => {
  return (
    <Box sx={containerStyles}>
      <Typography variant="h6">Statistics</Typography>
      <StatisticItem 
        title="Total" 
        resource={resource || ''} 
      />
      {!onlyTotal && (
        <>
          <StatisticItem
            title="Active"
            resource={resource || ''}
            filter={{ [statusName]: PublicationStatus.active }}
          />
          <StatisticItem
            title="Draft"
            resource={resource || ''}
            filter={{ [statusName]: PublicationStatus.draft }}
          />
          <StatisticItem
            title="Testing"
            resource={resource || ''}
            filter={{ [statusName]: PublicationStatus.testing }}
          />
          <StatisticItem
            title="Retired"
            resource={resource || ''}
            filter={{ [statusName]: PublicationStatus.retired }}
          />
          <StatisticItem
            title="Unknown"
            resource={resource || ''}
            filter={{ [statusName]: PublicationStatus.unknown }}
          />
        </>
      )}
    </Box>
  );
};
