import { Box } from "@mui/material";
import { Edit, SaveButton, SimpleForm, Toolbar } from "react-admin";
import { useMutationErrorNotify } from "src/common/handleMutationError";

import PolicyForm from "./policyForm";

const PolicyEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const PolicyEdit = () => {
  const notify = useMutationErrorNotify();

  return (
    <Edit
      title="Policy"
      mutationMode="pessimistic"
      mutationOptions={{
        onError: notify
      }}
    >
      <SimpleForm toolbar={<PolicyEditToolbar />}>
        <Box
          display="flex"
          flexDirection="column"
          width={{ xs: "100%", xl: 1414 }}
          paddingBottom="100px"
          gap="3%"
        >
          <PolicyForm isEditing/>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default PolicyEdit;
