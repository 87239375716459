import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { DeletionRequestInfoTable } from "src/dashboard/components/DeletionRequestInfoTable";
import { InfoCard } from "src/dashboard/components/InfoCard";
import { InfoTable } from "src/dashboard/components/InfoTable";
import { OccurrenceInfoTable } from "src/dashboard/components/OccurrenceInfoTable";

const InstitutionStatistics = () => {
  const { id: institutionId } = useParams();

  return (
    <Box>
      <Box sx={{ marginTop: 1.5, gap: 1 }} flexWrap="wrap" display="flex">
        <InfoCard
          resource="statistics/all-practitioners"
          label="Practitioners in Institution"
          institutionId={institutionId}
        />
        <InfoCard
          resource="statistics/all-patients"
          institutionId={institutionId}
          label="Patients in Institution "
        />
        <InfoCard
          resource="statistics/assigned-occurrences"
          label="Assigned activity occurrences"
          institutionId={institutionId}
        />
        <InfoCard
          resource="statistics/completed-occurrences"
          label="Completed activity occurrences"
          institutionId={institutionId}
        />
      </Box>
      <Box sx={{ marginTop: 1.5, gap: 1 }} flexWrap="wrap" display="flex">
        <InfoTable
          columns={["Active patients"]}
          headerLabel="Active patients"
          resource="statistics/active-patients"
          subtitle="Logged-in in given period"
          institutionId={institutionId}
        />
        <InfoTable
          headerLabel="Patient invitations"
          columns={["Invitations sent"]}
          resource="statistics/patient-invitations"
          institutionId={institutionId}
        />
        <InfoTable
          columns={["New patients"]}
          headerLabel="New patients"
          resource="statistics/new-patients"
          institutionId={institutionId}
        />
        <OccurrenceInfoTable institutionId={institutionId} />
        <DeletionRequestInfoTable institutionId={institutionId} />
        <InfoTable
          columns={["Active practitioners"]}
          headerLabel="Active practitioners"
          resource="statistics/active-practitioners"
          subtitle="Logged-in in given period"
          institutionId={institutionId}
        />
        <InfoTable
          columns={["Filled in therapy goals"]}
          headerLabel="Filled in therapy goals"
          resource="statistics/therapy-goals"
          valueType="percent"
          institutionId={institutionId}
        />
      </Box>
    </Box>
  );
};

export default InstitutionStatistics;
