import { Box, Card, Typography } from "@mui/material";
import { useContext } from "react";
import { Link, useGetOne } from "react-admin";
import { useParams } from "react-router-dom";
import { AppRoleContext } from "src/context/AppRoleContext/AppRoleContext";
import { InfoCard } from "src/dashboard/components/InfoCard";
import { InfoTable } from "src/dashboard/components/InfoTable";
import { OccurrenceInfoTable } from "src/dashboard/components/OccurrenceInfoTable";
import { Practitioner } from "src/practitioners/types";

const PractitionerStatistics = () => {
  const { institutionId, practitionerId } = useParams();
  const { role } = useContext(AppRoleContext);

  const { data } = useGetOne<Practitioner & { id: string }>(
    "users/practitioners",
    {
      id: practitionerId,
    }
  );

  return (
    <Card sx={{ margin: 1, padding: 1 }}>
      <Link to={`/institutions/${institutionId}/show/1`}>Back to list</Link>
      <Typography
        variant="h4"
        sx={{
          color: "#4F4F4F",
          textAlign: "left",
          margin: "16px 0",
        }}
      >
        {"Practitioner "}
        {role === "SUPERUSER" && data
          ? `${data.firstName} ${data.lastName}`
          : practitionerId}
      </Typography>
      <Box sx={{ marginTop: 1.5, gap: 1 }} flexWrap="wrap" display="flex">
        <InfoCard
          resource="statistics/all-patients"
          label="Practitioner's patients"
          institutionId={institutionId}
          practitionerId={practitionerId}
        />
        <InfoCard
          resource="statistics/patient-invitations"
          institutionId={institutionId}
          practitionerId={practitionerId}
          label="Practitioner’s patient invitations in total"
        />
        <InfoCard
          resource="statistics/assigned-occurrences"
          label="Assigned activity occurrences"
          institutionId={institutionId}
          practitionerId={practitionerId}
        />
        <InfoCard
          resource="statistics/completed-occurrences"
          label="Completed activity occurrences"
          institutionId={institutionId}
          practitionerId={practitionerId}
        />
      </Box>
      <Box sx={{ marginTop: 1.5, gap: 1 }} flexWrap="wrap" display="flex">
        {/* <InfoTable
          columns={["Active patients"]}
          headerLabel="Active patients"
          resource="statistics/active-patients"
          subtitle="Logged-in in given period"
          institutionId={institutionId}
          practitionerId={practitionerId}
        /> */}
        <InfoTable
          headerLabel="Patient invitations"
          columns={["Invitations sent"]}
          resource="statistics/patient-invitations"
          institutionId={institutionId}
          practitionerId={practitionerId}
        />
        <InfoTable
          columns={["New patients"]}
          headerLabel="Onboard patients"
          resource="statistics/new-patients"
          institutionId={institutionId}
          practitionerId={practitionerId}
        />
        <OccurrenceInfoTable
          institutionId={institutionId}
          practitionerId={practitionerId}
        />
        {/* <InfoTable
          columns={["Filled in therapy goals"]}
          headerLabel="Filled in therapy goals"
          resource="statistics/therapy-goals"
          valueType="percent"
          institutionId={institutionId}
          practitionerId={practitionerId}
        /> */}
        {/* <DeletionRequestInfoTable
          institutionId={institutionId}
          practitionerId={practitionerId}
        /> */}
      </Box>
    </Card>
  );
};

export default PractitionerStatistics;
