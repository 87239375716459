import {
  CreateParams,
  CreateResult,
  Identifier,
  ResourceCallbacks,
  UpdateParams,
  UpdateResult
} from "react-admin";
import { getHeadersForCustomDataProviderMethod } from "src/auth";

import { Activity } from "./types";

const activityPictures = new Map<
  Identifier,
  | { src: string; rawFile?: File; title?: string }
  | { src: string; weight: number; height: number }
>();

function handleBeforeSave<
  T extends
    | CreateParams<Activity & { id: string }>
    | UpdateParams<Activity & { id: string }>
>(params: T): Promise<T> {
  if (params.data.picture) {
    activityPictures.set("id" in params ? params.id : "", params.data.picture);

    return Promise.resolve({
      ...params,
      data: {
        ...params.data,
        picture: undefined
      }
    });
  }

  return Promise.resolve(params);
}

async function handleAfterSave<
  T extends
    | CreateResult<Activity & { id: string }>
    | UpdateResult<Activity & { id: string }>
>(result: T, initialId?: string): Promise<T> {
  const key = typeof initialId === "string" ? initialId : result.data.id;

  if (activityPictures.has(key)) {
    const data = activityPictures.get(key);

    if (!("rawFile" in data)) {
      activityPictures.delete(key);

      return result;
    }

    const body = new FormData();

    body.append("picture", data.rawFile);

    const headers = getHeadersForCustomDataProviderMethod();

    headers.delete("Content-Type");
    headers.append("enctype", "multipart/form-data");

    const response = await fetch(
      `/api/superuser/activities/${result.data.id}/picture`,
      {
        method: "PUT",
        headers,
        body
      }
    );

    if (!response.ok) {
      throw new Error("Activity data saved, but image upload has failed");
    }

    const { picture } = (await response.json()) as {
      picture: { src: string; weight: number; height: number };
    };

    activityPictures.delete(key);

    return {
      ...result,
      data: { ...result.data, picture }
    };
  } else if (result.data.picture) {
    const headers = getHeadersForCustomDataProviderMethod();
    const response = await fetch(
      `/api/superuser/activities/${result.data.id}/picture`,
      {
        method: "DELETE",
        headers
      }
    );

    if (!response.ok) {
      throw new Error("Activity data saved, but image removal has failed");
    }

    return { ...result, data: { ...result.data, picture: undefined } };
  }

  return result;
}

export const activityLifecycleCallbacks: ResourceCallbacks<
  Activity & { id: string }
> = {
  resource: "activities",
  beforeCreate(params) {
    return handleBeforeSave(params);
  },
  afterCreate(result) {
    return handleAfterSave(result, "");
  },
  beforeUpdate(params) {
    return handleBeforeSave(params);
  },
  afterUpdate(result) {
    return handleAfterSave(result);
  }
};
